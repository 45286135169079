import { postRequest } from "../../utils/apiHelper";

export const userService = {
    getUser,
    getDetail,
    saveUser,
    updateUser,
    deleteUser
};

async function getUser(data) {
    return await postRequest(`users/${data.queryParams}`, data.filters);
}
async function getDetail(queryParams = "") {
    return await postRequest(`users/edit${queryParams}`);
}
async function saveUser(queryParams = "") {
    return await postRequest(`users/save${queryParams}`);
}
async function updateUser(queryParams = "") {
    return await postRequest(`users/update${queryParams}`);
}
async function deleteUser(queryParams = "") {
    return await postRequest(`users/delete${queryParams}`);
}