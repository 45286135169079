import { postRequest } from "../../utils/apiHelper";

export const searchService = {
    searchAgencies,
    getInvitation

};

async function searchAgencies(query) {
    return await postRequest('search', { text: query });
}
async function getInvitation(data) {
    return await postRequest(`search/invite${data?.queryParams}`, data.filters);
}

// async function getAgencies(data) {
//     return await postRequest(`agency/${data?.queryParams}`, data.filters);
//   }


