import React from "react";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import NewUserGroup from "../containers/UsersGroup/NewUserGroup";
import UsersGroup from "../containers/UsersGroup";
import Profile from "../containers/Users/Profile";
import Users from "../containers/Users";
import EditProfile from "../containers/Users/EditProfile";

const userRoutes = [
    {
        path: '/users',
        element: <ProtectedRoute />,
        children: [
            {
                path: '',
                element: <Users />
            },
            {
                path: 'profile',
                element: <Profile />
            },
            {
                path: 'group',
                element: <UsersGroup />
            },
            {
                path: 'new',
                element: <NewUserGroup />
            },
            {
                path: 'profile/:id',
                element: <EditProfile />
            }
        ]
    }
]

export default userRoutes
