import { createAsyncThunk } from "@reduxjs/toolkit";
import { searchService } from "../services/search_service";
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";

export const searchAgencies = createAsyncThunk(
    "search/searchAgencies",
    async (query, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setLoading("Searching..."));
            const response = await searchService.searchAgencies(query);
            dispatch(setLoading(null));
            console.log("4 datas", response)
            return response.data;
        } catch (err) {
            dispatch(setLoading(null));
            parseErrorResponses(err.response || err, dispatch);
            return rejectWithValue(err);
        }
    }
);


export const getInvitation = createAsyncThunk(
    "agencies/getInvitation",
    async (data, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setLoading("Logging Agencies..."));
            const response = await searchService.getInvitation(data);
            console.log("Invitation action", response)
            dispatch(setLoading(null));
            return response?.data?.data;
        } catch (err) {
            dispatch(setLoading(null));
            parseErrorResponses(err?.response || err, dispatch);
            return rejectWithValue(err);
        }
    }
);



export const clearSearchResults = () => (dispatch) => {
    dispatch({ type: "search/clearSearchResults" });
};
