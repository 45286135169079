import React, { useState } from "react";
import {
  Grid,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Table as Core,
  TableBody,
  Typography,
  Pagination,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
import Style from "./style";
import TableHeader from "./TableHeader";
import Filter from "../Filter";
import { CopyIcon, SortIconDown, SortIconUp } from "../../assets/svg";
import theme from "../../theme";

const Table = (props) => {
  const {
    className,
    headers,
    data,
    cellMap = {},
    pagination,
    search,
    handlePagination,
    handlePageSize,
    title,
    handleSearch,
    onSort,
    filter,
    handleFilter,
    removeFilter,
    options,
    appliedFilter,
    addUser,
    sortField
  } = props;

  const [filters, setFilters] = useState(false)
  const { num_pages, page_number, page_size, count } = pagination || {};
  const startIndex = page_number > 1 ? (page_number - 1) * page_size : 0;
  let endIndex = startIndex + page_size;
  endIndex = endIndex > count ? count : endIndex;

  const renderCellValue = (field, value, rowData) => {
    const CellComponent = cellMap && cellMap[field];
    if (CellComponent) {
      return <CellComponent item={value} rowData={rowData} />;
    } else {
      return <Column value={value} field={field} />
    }
  };

  const Column = (props) => {

    const { value, field } = props;
    const [copied, setCopied] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const handleMouseOver = () => {
      setIsVisible(true);
    };
    const handleMouseOut = () => {
      setIsVisible(false);
    };
    return (
      <Grid className="dflex aCenter bgNone"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={() => {
        }}
      >
        <Typography style={{ whiteSpace: "nowrap" }}>
          {value ? (value === " " ? "-" : value) : "-"}</Typography>
        <div style={{ width: 26 }}>
          {field.includes("email") &&
            <div style={{ display: isVisible ? 'block' : 'none' }} className="ml-1">
              <Tooltip title={copied ? "Copied!" : "Copy"}><IconButton onClick={() => {
                navigator.clipboard.writeText(value);
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
              }}><CopyIcon width="16" height="16" /></IconButton></Tooltip>
            </div>
          }
        </div>
      </Grid>
    )
  }

  const tableCell = (header, rowData) => {
    const field = header?.field;
    return (
      <TableCell key={`cell-value-${field}`}>
        <Typography component="div" color={"#64748B"} >
          {renderCellValue(field, rowData[field], rowData)}
        </Typography>
      </TableCell>
    );
  };

  const tableRow = (rowData, i) => {
    return (
      <TableRow key={`row-value-${i}`}>
        {headers.map((header) => {
          return tableCell(header, rowData);
        })}
      </TableRow>
    );
  };
  const onSearch = (val) => {
    handleSearch(val);
  };

  const onFilter = () => {
    setFilters(!filters)
  };

  const Header = (props) => {

    const { m, sortField } = props
    const [sort, setSort] = useState(!sortField?.sort)

    //Mouse Hover for Sorting 
    const [isVisible, setIsVisible] = useState(false);
    const handleMouseOver = () => {
      setIsVisible(true);
    };
    const handleMouseOut = () => {
      setIsVisible(false);
    };
    return <TableCell key={`cell-header-${m.field}`} style={{ padding: 10 }}>
      <Grid className="dflex aCenter bgNone sortBg"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={() => {
          setSort(!sort)
          onSort({ sort: sort, field: m.field })
        }}
      >
        <Typography variant="body2" color={"grey.light"} style={{ whiteSpace: "nowrap" }}>
          {m?.label}
        </Typography>

        <div style={{ width: 25, height: 24 }}>
          {sortField &&
            <div style={{ display: (isVisible || sortField.field === m?.field) && m.field !== "action" ? 'block' : 'none' }} className="ml-1">
              {sortField.sort ? <SortIconUp /> : <SortIconDown />}
            </div>}
        </div>
      </Grid>
    </TableCell>
  }


  return (
    <Grid className={`${className} h-100`} style={{ background: theme.palette.containers.body, padding: "0px 20px" }}>

      <TableHeader
        title={title}
        onSearch={onSearch}
        search={search}
        headers={headers}
        onFilter={onFilter}
        filter={filter}
        addUser={addUser}
      />

      <div style={{ padding: "15px", paddingBottom: 0, background: "white", height: "calc(100% - 90px)", borderRadius: 12 }}>
        {filters && <Filter options={options} handleFilter={handleFilter} removeFilter={removeFilter} setFilters={setFilters} appliedFilter={appliedFilter} />}
        <Grid container style={filters ? { height: "calc(100% - 65px)" } : { height: "100%" }} justifyContent={"space-between"} alignItems={"center"}>
          <TableContainer className="tableContainers">
            <Core stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {headers.map((m, i) => {
                    return (
                      <Header m={m} sortField={sortField} />
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.length > 0 ? (
                  data?.map(tableRow)
                ) : (
                  <TableRow>
                    <TableCell colSpan={headers.length}>
                      No Records Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Core>
          </TableContainer>
          <div className="pagination-wrapper">
            <div className="form-group">
              <label className="form-label">Show Rows:</label>
              <Select
                className="form-field"
                size="small"
                value={page_size}
                onChange={handlePageSize}
              >
                <MenuItem value={5}>5 Items</MenuItem>
                <MenuItem value={10}>10 Items</MenuItem>
                <MenuItem value={25}>25 Items</MenuItem>
                <MenuItem value={50}>50 Items</MenuItem>
              </Select>
            </div>
            <div className="form-group">
              {startIndex || 1} to {endIndex} of {pagination?.count}
            </div>
            <Pagination
              // className="pagination-wrapper"
              className="dflax aCenter jCenter"
              count={num_pages}
              page={page_number}
              onChange={handlePagination}
            />
          </div>
        </Grid>
      </div>
    </Grid>
  );
};

export default styled(Table)(Style);
