import {
  Grid,
  Typography,
  styled
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  CopyIcon
} from "../../../assets/svg";
import Table from "../../../components/Table";
import { getAgenciesPayment } from "../../../store/actions/agenciesAction";
import {
  applyFilter,
  setOrdering,
  setPage,
  setPageSize,
  setSearch,
  clearFilter
} from "../../../store/reducers/agenciPaymentReducer";
import Style from "./style";

const AgencyPayment = (props) => {
  const { className } = props;
  const dispatch = useDispatch();
  let { agency_id } = useParams();

  const { list } = useSelector((state) => state.agenciesPayment);
  const { pagination, search, ordering, orderBy, orderDirection, filter } = list;

  const [tableData, setTableData] = useState([]);

  const subtractOneMonth = (dateString) => {
    if (!dateString) return;
    let inputDate = new Date(dateString);
    inputDate.setMonth(inputDate.getMonth() - 1);

    const formatDate = (date) => {
      return date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      });
    };

    let formattedDate = formatDate(inputDate);
    return formattedDate;
  };

  useEffect(() => {
    let tabData = list?.results?.map((ele) => {
      let formattedExpiryDate = new Date(ele.expiry).toLocaleDateString(
        "en-GB",
        {
          day: "2-digit",
          month: "long",
          year: "numeric",
        }
      );
      let formatCreatedAt = new Date(ele.created_at).toLocaleDateString(
        "en-GB",
        {
          day: "2-digit",
          month: "long",
          year: "numeric",
        }
      );
      return {
        ...ele,
        created_at: formatCreatedAt,
        expiry: `${subtractOneMonth(ele.expiry)} - ${formattedExpiryDate}`,
      };
    });
    setTableData(tabData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list?.results]);


  const getInvoicePayments = (
    page_size,
    page_number,
    search,
    ordering,
    filter
  ) => {

    let queryParams = "?";

    queryParams += `page_size=${page_size}`;

    queryParams += `&page=${page_number}`;

    if (search) {
      queryParams += `&search=${search}`;
    }

    if (ordering) queryParams += `&sortField=${ordering.field}&sortOrder=${ordering.sort ? 'asc' : 'desc'}`;

    const data = {
      queryParams: `${agency_id}${queryParams}`,
      filters: filter
    }

    dispatch(getAgenciesPayment(data));
  };

  const handlePageSize = (e) => {
    getInvoicePayments(
      e.target.value,
      pagination.page_number,
      search,
      ordering,
      orderDirection
    );
    dispatch(setPageSize(e.target.value));
  };

  const handlePagination = (e, pageValue) => {
    getInvoicePayments(
      pagination.page_size,
      pageValue,
      search,
      ordering,
      orderDirection
    );
    dispatch(setPage(pageValue));
  };

  const handleSearch = (searchVal) => {
    getInvoicePayments(
      pagination.page_size,
      pagination.page_number,
      searchVal,
      ordering,
      orderDirection
    );
    dispatch(setSearch(searchVal));
  };


  const onSort = (sortBy) => {
    getInvoicePayments(
      pagination.page_size,
      pagination.page_number,
      search,
      sortBy
    );
    dispatch(setOrdering(sortBy));
  };

  const handleFilter = (data) => {

    const prevFilter = {
      ...filter,
      [data.title]: data.data
    };

    getInvoicePayments(
      pagination.page_size,
      pagination.page_number,
      search,
      ordering,
      prevFilter
    );
    dispatch(applyFilter(data))
  }

  const removeFilter = () => {
    dispatch(clearFilter())
  }


  const headers = [
    { field: "invoice_amount", label: "Amount", sort: true },
    { field: "status", label: "Status", sort: true },
    { field: "created_at", label: "Created On", sort: true },
    { field: "expiry", label: "duration", sort: true },
    { field: "invoice_id", label: "Invoice no", sort: true },
  ];

  useEffect(() => {
    getInvoicePayments(
      pagination.page_size,
      pagination.page_number,
      search,
      ordering,
      orderDirection,
      filter
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency_id]);


  // const statusData = [
  //   "Paid",
  //   "Draft",
  //   "Failed",
  //   "Refunded",
  //   "Cancelled",
  //   "Requested for refund",
  //   "Deleted",
  // ];

  // const paymentData = [
  //   "Card",
  //   "Digital Payments",
  //   "echecks",
  //   "Cash",
  //   "Paper Check"
  // ];

  const Custom = ({ text }) => {
    const [copy, setCopy] = useState(false);
    return (
      <Grid container alignItems="center">
        <Typography variant="body1" style={{ marginRight: 8 }}>
          {text}
        </Typography>
        <CopyToClipboard
          text={text}
          onCopy={() => {
            setCopy(true);
            setTimeout(() => setCopy(false), 2000);
          }}
        >
          <CopyIcon />
        </CopyToClipboard>
        {copy && <Typography>Copied</Typography>}
      </Grid>
    );
  };


  const StatusButton = (props) => {
    const { lable, style } = props;
    return (
      <Grid>
        <div style={{ ...style }}>
          {lable}
        </div>
      </Grid>
    );
  };

  const Status = (props) => {
    const { rowData } = props;
    return (
      <div>
        {rowData.status === "paid" ? (
          <StatusButton lable={"Paid"} style={{
            backgroundColor: "#bde9c7", borderRadius: "5px", border: "none", color: "#3c8153", padding: "5px 10px", minWidth: "20px", display: "flex", justifyContent: "center"
          }} />
        ) : rowData.status === "draft" ? (
          <StatusButton lable={"Draft"} style={{
            backgroundColor: "#E8E8E8", borderRadius: "5px", border: "none", color: "#808080", padding: "5px 10px", minWidth: "20px", display: "flex", justifyContent: "center"
          }} />
        ) : ""}
      </div>
    );
  };

  const AmountColor = ({ text }) => {
    return (
      <Grid>
        <Typography className="payment">{text}</Typography>
      </Grid>
    );
  };

  const options = [
    {
      title: "Status",
      type: "checkbox",
      option: [
        { value: "Paid" },
        { value: "Draft" },
      ]
    },
    // {
    //   title: "Price",
    //   type: "price",
    // }
  ];


  return (
    <Grid container className={className}>
      <Grid className="dflex mt-5">
        <Table
          title={"Agency Payments"}
          headers={headers}
          data={tableData}
          pagination={pagination}
          search={search}
          handlePagination={handlePagination}
          handlePageSize={handlePageSize}
          handleSearch={handleSearch}
          ordering={ordering}
          orderBy={orderBy}
          onSort={onSort || []}
          filter={true}
          appliedFilter={filter}
          handleFilter={handleFilter}
          removeFilter={removeFilter}
          options={options}
          cellMap={{
            invoice_id: ({ rowData }) => {
              return <Custom text={rowData.invoice_id} />;
            },
            status: ({ rowData }) => {
              return <Status rowData={rowData} />;
            },
            invoice_amount: ({ rowData }) => {
              return <AmountColor text={rowData.invoice_amount} />;
            },
          }}
        />
      </Grid>

    </Grid>
  );
};

AgencyPayment.defaultProps = {};

AgencyPayment.propTypes = {};

export default styled(AgencyPayment)(Style)
