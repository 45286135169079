import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
    Button,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,

} from "@mui/material";
import Style from "./style";
import { styled } from "@mui/system";
import { BackArrowIcon, ViewAllIcon } from "../../assets/svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { searchAgencies, clearSearchResults } from "../../store/actions/searchAction";
import { Defaultlogo } from "../../assets/svg";
import { InvoiceIcon } from "../../assets/svg";
//import { formatDate } from "../../helpers/utils";
const ShowResultsComponent = (props) => {
    const { className, searchSuggestion, handleAllResults, inputref } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const TB_invoice = useSelector((state) => state.search.invoices.data) || [];
    const TB_agencies = useSelector((state) => state.search.agencies.data) || [];
    const TB_users = useSelector((state) => state.search.users.data) || [];
    const TB_invitation = useSelector((state) => state.search.invitations.data) || [];
    console.log("TB_invitation", TB_invitation)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const searchText = props.searchText || queryParams.get('searchText') || '';

    useEffect(() => {
        if (inputref && inputref.current) {
            inputref.current.focus();
        }
    }, [searchText, inputref]);

    useEffect(() => {
        if (searchText && searchText.length > 1) {
            dispatch(searchAgencies(searchText));
        } else {
            dispatch(clearSearchResults());
        }
    }, [dispatch, searchText]);

    const handleback = () => {
        navigate("/agencies");
    }
    const handlepath = (path) => {
        navigate(path);
    }

    const formatDateDDMMYY = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString(); // Use slice(-2) to get last two digits of year
        return `${year}-${month}-${day}`;
    };
    const getStatusText = (statusCode) => {
        switch (statusCode) {
            case 0:
                return "Draft";
            case 1:
                return "Sent";
            case 2:
                return "Read";
            case 4:
                return "Rejected";
            default:
                return "Unknown";
        }
    }
    return (
        <Grid className={`${className} ${searchSuggestion ? 'searchPopUp' : 'searchPage'}`}>
            <Grid container className="resultsHeader" justifyContent={"space-between"}>
                <Grid className="flexAlign">
                    {!searchSuggestion && (
                        <IconButton
                            onClick={handleback}
                            sx={{ mr: 1, width: '30px', height: '30px', p: '2px' }}>
                            <BackArrowIcon />
                        </IconButton>
                    )}
                    <Typography variant="h6" color={"textSecondary"} sx={{ pr: '5px' }}>Showing results for </Typography>
                    <Typography variant="h6" className="fw-600"> “{searchText}”</Typography>
                </Grid>




                {searchSuggestion && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            handleAllResults();
                            navigate(`/results?searchText=${encodeURIComponent(searchText)}`, { replace: true });
                        }}
                    >
                        Show All Results
                    </Button>

                )}
            </Grid>

            <Grid className="resultContainer" sx={{ p: 2 }}>
                {/* Invoice Table */}
                <Grid className="header" container justifyContent={"space-between"} sx={{ mb: TB_invoice.length > 0 ? 4 : 0 }}>
                    <Typography variant="body1" className="resultHeader" color={"primary"} >
                        Invoices
                    </Typography>
                    {!searchSuggestion && TB_invoice.length > 0 && (
                        <Button
                            variant="outlined"
                            color="primary"
                            endIcon={<ViewAllIcon />}
                            onClick={() => handlepath(`/agencies/invoice?searchText=${encodeURIComponent(searchText)}`)}
                        >
                            View all
                        </Button>


                    )}
                </Grid>
                {TB_invoice.length > 0 ? (
                    <TableContainer >
                        <Table
                            sx={{
                                minWidth: 650,
                                '& .MuiTableCell-root': {
                                    padding: '4px 16px',
                                },
                            }} aria-label="invoice table"
                        >

                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1" color={"textSecondary"}>
                                            Invoice No
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" color={"textSecondary"}>
                                            Email
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" color={"textSecondary"}>
                                            Currency
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" color={"textSecondary"}>
                                            Client
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" color={"textSecondary"}>
                                            Amount
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" color={"textSecondary"}>
                                            Due Date
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ paddingTop: '0px' }}>
                                {TB_invoice.map((invoice, index) => (
                                    <TableRow key={index}>
                                        <TableCell>

                                            <Typography variant="body1"> <InvoiceIcon />{invoice.invoice_number}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">{invoice.customer_email}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">{invoice.invoice_currency}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">{invoice.customer_name}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">${invoice.invoice_total}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">{invoice.due_date}</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                        No Invoice Data Available
                    </Typography>
                )}

                {/* Agencies Table */}
                <Grid className="headers" container justifyContent={"space-between"} sx={{ mt: TB_agencies.length > 0 ? 4 : 0 }}>
                    <Typography variant="body1" className="resultHeader" color={"primary"}>
                        Agencies
                    </Typography>
                    {!searchSuggestion && TB_agencies.length > 0 && (
                        <Button
                            variant="outlined"
                            color="primary"
                            endIcon={<ViewAllIcon />}
                            onClick={() => handlepath(`/agencies/list?searchText=${encodeURIComponent(searchText)}`)}
                        >
                            View all
                        </Button>
                    )}
                </Grid>
                {TB_agencies.length > 0 ? (
                    <TableContainer sx={{ mt: 2 }}>
                        <Table
                            sx={{
                                minWidth: 650,
                                '& .MuiTableCell-root': {
                                    padding: '4px 16px',
                                },
                            }} aria-label="agencies table"
                        >

                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1" color={"textSecondary"}>
                                            Agency Name
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" color={"textSecondary"}>
                                            Email
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" color={"textSecondary"}>
                                            Last Paid
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {TB_agencies.map((agency, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Grid container alignItems="center" spacing={1}>
                                                {agency.agency_logo ? (
                                                    <img
                                                        src={agency.agency_logo}
                                                        alt="logo"
                                                        style={{ width: '24px', height: '24px', marginRight: '8px', marginTop: '8px' }}
                                                    />
                                                ) : (
                                                    <Defaultlogo style={{ width: '25px', height: '25px', marginRight: '8px', marginTop: '8px' }} />
                                                )}
                                                <Grid item>
                                                    <Typography variant="body1">{agency.agency_name}</Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">{agency.agency_email}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">{agency.last_paid}</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                        No Agency Data Available
                    </Typography>
                )}

                {/* Users Table */}
                <Grid className="headers" container justifyContent={"space-between"} sx={{ mt: TB_users.length > 0 ? 4 : 0 }}>
                    <Typography variant="body1" className="resultHeader" color={"primary"}>
                        Users
                    </Typography>
                    {!searchSuggestion && TB_users.length > 0 && (
                        <Button variant="outlined" color="primary" endIcon={<ViewAllIcon />}
                            onClick={() => handlepath(`/agencies/users?searchText=${encodeURIComponent(searchText)}`)}>
                            View all
                        </Button>
                    )}
                </Grid>
                {TB_users.length > 0 ? (
                    <TableContainer sx={{ mt: 2 }}>
                        <Table
                            sx={{
                                minWidth: 650,
                                '& .MuiTableCell-root': {
                                    padding: '4px 16px',
                                },
                            }} aria-label="user table"
                        >

                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1" color={"textSecondary"}>
                                            First Name
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" color={"textSecondary"}>
                                            Last Name
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" color={"textSecondary"}>
                                            Username
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" color={"textSecondary"}>
                                            Email
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {TB_users.map((user, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Typography variant="body1">{user.first_name}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">{user.last_name}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">{"@" + user.username}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1">{user.email}</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                        No User Data Available
                    </Typography>
                )}


                {/* Invitations Table */}
                {!searchSuggestion && TB_invitation.length > 0 && (
                    <Grid>
                        <Grid className="headers" container justifyContent={"space-between"} sx={{ mt: 4 }}>
                            <Typography variant="body1" className="resultHeader" color={"primary"}>
                                Invitations
                            </Typography>
                            {!searchSuggestion && TB_invitation.length > 0 && (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    endIcon={<ViewAllIcon />}
                                    onClick={() => handlepath(`/search/invitation?searchText=${encodeURIComponent(searchText)}`)}
                                >
                                    View all
                                </Button>


                            )}
                        </Grid>
                        <TableContainer sx={{ mt: 2 }}>
                            <Table
                                sx={{
                                    minWidth: 650,
                                    '& .MuiTableCell-root': {
                                        padding: '4px 16px',
                                    },
                                }} aria-label="invitations table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle1" color={"textSecondary"}>
                                                Agency Name
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle1" color={"textSecondary"}>
                                                Email
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle1" color={"textSecondary"}>
                                                Invite Status
                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography variant="subtitle1" color={"textSecondary"}>
                                                Invited on
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {TB_invitation.map((invitation, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Typography variant="body1">{invitation.agency_name}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body1">{invitation.invite_email}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body1">{getStatusText(invitation.invite_status)}</Typography>
                                            </TableCell>

                                            <TableCell>
                                                <Typography variant="body1">{formatDateDDMMYY(invitation.created_on)}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                )}

            </Grid>
        </Grid>
    );
};

ShowResultsComponent.defaultProps = {
    searchSuggestion: false
};

ShowResultsComponent.propTypes = {
    searchSuggestion: PropTypes.bool,
    handleAllResults: PropTypes.func,
    searchText: PropTypes.string,
    inputref: PropTypes.object
};

const ShowResults = styled(ShowResultsComponent)(({ theme }) => Style(theme));

export default ShowResults;
