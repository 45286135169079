import theme from "../../../theme";

const Style = () => ({
  padding: 30,
  '.userGroupContainer': {
    height: "calc(100% - 46px)",
    overflow: "auto",
    padding: '20px 25px',
    borderRadius: '12px',
    background: theme.palette.containers.card,
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.06)',
    '& .MuiDivider-root': {
      borderColor: theme.palette.grey.border
    }
  },
  // '.accessTable': {
  //     border: `1px solid ${theme.palette.grey.border}`,
  //     borderRadius: '12px',
  //     '& .cell':{
  //         padding: '12px'
  //     },
  //     '& .emptySpace': {
  //         background: theme.palette.containers.body,
  //         height: '23px',
  //         borderTop: `1px solid ${theme.palette.grey.border}`,
  //         borderBottom: `1px solid ${theme.palette.grey.border}`
  //     },
  //     '& .switches': {
  //         '& .MuiFormControlLabel-root': {
  //             margin: '0px !important'
  //         },
  //         '& .MuiSwitch-root': {
  //             margin: '0px !important'
  //         }
  //     },
  //     '& .br-rt': {borderRight: `1px solid ${theme.palette.grey.border}`},
  //     '& .br-bt': {borderBottom: `1px solid ${theme.palette.grey.border}`},
  //     '& .br-tp': {borderTop: `1px solid ${theme.palette.grey.border}`},
  //     '& .br-lt': {borderLeft: `1px solid ${theme.palette.grey.border}`},
  // },
  // '.userItem': {
  //     background: theme.palette.containers.greyBg5,
  //     borderRadius: '12px',
  //     boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.08)',
  //     padding: '8px 14px',
  //     position: 'relative',
  //     '& .closeIcon': {
  //         position: 'absolute',
  //         right: '5px',
  //         top: '5px',
  //         width: '20px',
  //         width: '20px'
  //     }
  // },
  '.breadcrumb': {
    paddingBottom: 25,
    '& .MuiButton-contanedPrimary': {
      background: theme.palette.primary.main,
      color: '#fff',
      fontWeight: '600'
    }
  },
  '.activityItem': {
    borderRight: `1px solid ${theme.palette.grey.border}`,
    paddingRight: '24px',
    '&:last-child': {
      borderRight: 'none'
    }
  },
  '.otherAgenciesHeader': {
    borderBottom: `1px solid ${theme.palette.grey.border}50`,
    marginBottom: '10px',
    paddingBottom: '10px'
  },
  '.otherAgenciesItem': {
    padding: '8px 0px',
    borderBottom: `1px solid ${theme.palette.grey.border}50`,
  }
})

export default Style;