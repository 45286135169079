const Style = () => ({
    display: "flex",
    marginLeft: 10,
    padding: 10,
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "12px",
    minWidth: "500px",
    border: "1px solid #EFEFEF",

    ".filterBox": {

        minWidth: 130,
        height: 32,
        borderRadius: 7,
        border: "1px solid #64748B",
        paddingLeft: 5,
        paddingRight: 10,
        display: "flex",
        alignItems: "center",
        marginLeft: 10

    },
});
export default Style;

