import { createSlice } from "@reduxjs/toolkit";

import { getInvitation } from "../actions/searchAction";
const initialState = {
    list: {
        results: [],
        headers: [],
        pagination: {
            count: 0,
            next: null,
            previous: null,
            page_size: 10,
            num_pages: 0,
            page_number: 1,
        },
        search: "",
        filter: {
            Status: [],

        },
        ordering: { field: "created_on", sort: false },
    },

    loading: null,
};

export const inviteslice = createSlice({
    name: "invite",
    initialState,
    reducers: {
        setPageSize(state, action) {
            state.list.pagination.page_size = action.payload;
        },
        setPage(state, action) {
            state.list.pagination.page_number = action.payload;
        },
        setSearch(state, action) {
            state.list.pagination.page_number = 1;
            state.list.search = action.payload;
        },
        setOrdering(state, action) {
            state.list.ordering = action.payload;

        },
        addFilterRule(state, action) {
            state.list.filter.rules.push({
                index: (Math.max(state.list.filter.rules.map((m) => m.index)) || 0) + 1,
                field: "",
                operator: "",
                value: "",
            });
        },
        removeFilterRule(state, action) {
            state.list.filter.rules = state.list.filter.rules.filter(
                (f) => f.index !== action.payload
            );
        },
        updateFilterRule(state, action) {
            state.list.filter.rules = state.list.filter.rules.map((m) => {
                if (m.index === action.payload.index) {
                    return action.payload;
                }
                return m;
            });
        },
        applyFilter(state, action) {
            const title = action?.payload?.title
            state.list.pagination.page_number = 1;
            state.list.filter[title] = action?.payload?.data;
        },
        clearFilter(state) {
            state.list.pagination.page_number = 1;
            state.list.filter.Status = [];

        },
    },
    extraReducers: (builder) => {
        // add cases for getInvitation api calls
        builder
            .addCase(getInvitation.pending, (state) => {
                state.loading = true;
            })
            .addCase(getInvitation.fulfilled, (state, { payload }) => {
                state.list = {
                    ...state.list,
                    ...payload,
                };
                state.loading = false;
            })
            .addCase(getInvitation.rejected, (state) => {
                state.loading = false;
            });

    },
});

export const {
    setPage,
    setPageSize,
    setSearch,
    setOrdering,
    addFilterRule,
    removeFilterRule,
    updateFilterRule,
    applyFilter,
    clearFilter,
} = inviteslice.actions;

export default inviteslice.reducer;
