import { createAsyncThunk } from "@reduxjs/toolkit";
import { agenciesService } from "../services/agenciesService";
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";
import { applyList } from "../reducers/customPaymentReducer";

export const getAgencies = createAsyncThunk(
  "agencies/getAgencies",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading("Logging Agencies..."));
      const response = await agenciesService.getAgencies(data);
      console.log("Agencies action", response)
      dispatch(setLoading(null));
      return response?.data?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
);

export const getAgenciesDetail = createAsyncThunk(
  "agencies/getAgenciesDetail",
  async (data, { rejectWithValue, dispatch }) => {
    console.log("data", data)
    try {
      dispatch(setLoading("Logging Agencies..."));
      const response = await agenciesService.getAgenciesDetail(data);
      dispatch(setLoading(null));
      return response?.data?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
);

export const getAgenciesPayment = createAsyncThunk(
  "agencies/getAgenciesPayment",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading("Logging Agencies..."));
      const response = await agenciesService.getAgenciPayment(data);
      console.log("response", response)
      dispatch(setLoading(null));
      return response?.data?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
);

export const getAgenciesClient = createAsyncThunk(
  "agencies/getAgenciesClient",
  async (data, { rejectWithValue, dispatch }) => {
    console.log("data", data)
    try {
      dispatch(setLoading("Logging Agencies..."));
      const response = await agenciesService.getAgenciesClient(data);
      console.log("response", response)
      dispatch(setLoading(null));
      return response?.data?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
);

export const getAgenciesFlows = createAsyncThunk(
  "agencies/getAgenciesFlows",
  async (data, { rejectWithValue, dispatch }) => {
    console.log("data", data)
    try {
      dispatch(setLoading("Logging Agencies..."));
      const response = await agenciesService.getAgencyFlows(data);
      console.log("response", response)
      dispatch(setLoading(null));
      return response?.data?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
);

export const getCustomerPayement = createAsyncThunk(
  "agencies/getCustomerpayments",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading("Logging Agencies..."));
      const response = await agenciesService.getCustomerPayement(data);
      dispatch(setLoading(null));
      dispatch(applyList(response.data.data));
      return response?.data?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
)

export const getUser = createAsyncThunk(
  "agencies/getUser",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      console.log("12345", data)
      dispatch(setLoading("Logging Agencies..."));
      const response = await agenciesService.getUser(data);
      dispatch(setLoading(null));
      return response?.data?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
);

export const getAgencyTeams = createAsyncThunk(
  "agencies/getUser",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading("Logging Agencies..."));
      const response = await agenciesService.getProjectTeams(data);
      dispatch(setLoading(null));
      return response?.data?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
);

export const getUserInfo = createAsyncThunk(
  "agencies/getUserInfo",
  async (data, { rejectWithValue, dispatch }) => {
    try {

      dispatch(setLoading("Logging Agencies..."));
      const response = await agenciesService.getUserInfo(data);
      dispatch(setLoading(null));
      return response?.data?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
);
export const getInvoiceDetail = createAsyncThunk(
  "agencies/getInvoiceDetail",
  async (data, { rejectWithValue, dispatch }) => {
    try {

      dispatch(setLoading("Logging Agencies..."));
      const response = await agenciesService.getInvoiceDetail(data);
      dispatch(setLoading(null));
      return response?.data?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
);
export const getInvoice = createAsyncThunk(
  "agencies/invoice",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading("Logging Agencies..."));
      const response = await agenciesService.getInvoice(data);
      dispatch(setLoading(null));
      return response?.data?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
);


export const resetPasword = createAsyncThunk(
  'user/resetPassword',
  async (email, { rejectWithValue }) => {
    console.log("Email actoin", email)
    try {
      const response = await agenciesService.reset(email);
      console.log("reseturl", response)
      return response.data; // Assuming your API returns data on success

    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
