import theme from "../../theme";

const Style = () => ({
  padding: '10px 20px',
  background: "#FFF",
  borderRadius: "12px",
  zIndex: 1,
  ".MuiTableContainer-root": {
    // padding: '18px'
  },
  "& .tableContainers": {
    height: "calc(100% - 66px)",
    background: theme.palette.containers.card,
    // padding: 15,
    borderTopRightRadius: 12,
    borderTopLeftRadius: 12,
    "& .sortBg ": {
      padding: 8,
      width: "fit-content",
      '&:hover': {
        background: "#EFF4F9 !important",
        borderRadius: "8px"
      },
    },
  },
  ".MuiTableHead-root": {
    background: "#F8FAFC",
    borderRadius: "12px",
    "& .MuiTableCell-head": {
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      background: "#F8FAFC",
    },
    "& .MuiTableCell-head:last-child": {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
      background: "#F8FAFC",
    },
  },
  ".MuiTableCell-head": {
    borderBottom: "none",
    background: "#F8FAFC",
    "& .MuiTypography-root": {
      textTransform: "uppercase",
    },
  },
  ".MuiTableCell-body": {
    borderBottom: "1px solid #F2F0F0",
  },
  ".pagination-wrapper": {
    background: theme.palette.containers.card,
    borderBottomRightRadius: "12px",
    borderBottomLeftRadius: "12px",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px",
    "& .form-label": {
      fontWeight: "bold",
    },
    "& .form-field": {
      marginLeft: "8px",
      border: "1px solid #E2E8F0",
      borderRadius: "12px",
    },
  },
  ".MuiPagination-ul": {
    // border: "1px solid #ccc", // Add border here
    // borderRadius: "4px",
    // padding: "4px",
    "& .MuiPaginationItem-root": {
      border: "1px solid #E2E8F0",
      borderRadius: "15px",
      padding: "20px",
      width: "30px",
      height: "30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    "& .Mui-selected": {
      color: "#2563EB",
    },
  },
  ".th-wrapper": {
    display: "flex",
    padding: "8px 12px",
    alignItems: "center",
    justifyContent: "space-between",
    "& .th-title": {},
    "& .th-search": {},
  },
});

export default Style;
