import { Button, Dialog, Grid, Typography, styled } from "@mui/material"
import DialogStyles from "./style";


const Delete = (props) => {
    const { open, handleClose, handleDelete, description, className } = props;
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="xs"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Grid className={className}>
                <Grid>
                    <Typography className="description">{description}</Typography>
                </Grid>
                <Grid className="button">
                    <Button variant="contained" color="primary" size="small" className="mr-1 btn" onClick={handleDelete}>Confirm</Button>

                    <Button variant="contained" color="secondary" className="mr-1 btn" size="small"
                        onClick={handleClose}
                    >Cancel</Button>
                </Grid>
            </Grid>
        </Dialog>
    )
}
export default styled(Delete)(DialogStyles);