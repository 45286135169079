import { createSlice } from "@reduxjs/toolkit";
import { getCustomerPayement } from "../actions/agenciesAction";

const initialState = {
    list: {
        results: [],
        headers: [],
        pagination: {
            count: 0,
            next: null,
            previous: null,
            page_size: 5,
            num_pages: 0,
            page_number: 1,
        },
        search: "",
        filter: {
            Status: []
        },
        ordering: { field: "invoice_id", sort: false },
        // ordering: "invoice_id",
        // orderBy: [{ field: "invoice_id", label: "invoice no" }, { field: "invoice_amount", label: "Amount" }, { field: "status", label: "status" }, { field: "expiry", label: "Duration" }, { field: "agency_name", label: "Name" }]
    },
    userDetail: {},
    loading: null,
};


export const customerPaymentsSlice = createSlice({
    name: "customerpayments",
    initialState,
    reducers: {
        setPageSize(state, action) {
            state.list.pagination.page_size = action.payload;
        },
        setPage(state, action) {
            state.list.pagination.page_number = action.payload;
        },
        setSearch(state, action) {
            state.list.pagination.page_number = 1;
            state.list.search = action.payload;
        },
        setOrdering(state, action) {
            state.list.ordering = action.payload;
        },
        addFilterRule(state, action) {
            state.list.filter.rules.push({
                index: (Math.max(state.list.filter.rules.map((m) => m.index)) || 0) + 1,
                field: "",
                operator: "",
                value: "",
            });
        },
        removeFilterRule(state, action) {
            state.list.filter.rules = state.list.filter.rules.filter(
                (f) => f.index !== action.payload
            );
        },
        updateFilterRule(state, action) {
            state.list.filter.rules = state.list.filter.rules.map((m) => {
                if (m.index === action.payload.index) {
                    return action.payload;
                }
                return m;
            });
        },
        applyFilter(state, action) {
            state.list.pagination.page_number = 1;
            state.list.filter[action.payload.title] = action.payload.data
            // state.list.filter.params = action.payload;
        },
        clearFilter(state) {
            state.list.pagination.page_number = 1;
            state.list.filter = initialState.list.filter
            // state.list.filter.params = "";
            // state.list.filter.rules = [{ index: 0, field: "", value: "" }];
        },
        applyList(state, action) {
            state.list.results = action.payload
        }
    },
    extraReducers: (builder) => {
        // add cases for getCustomerPayement api calls
        builder
            .addCase(getCustomerPayement.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCustomerPayement.fulfilled, (state, { payload }) => {

                state.list = {
                    ...state.list,
                    ...payload,

                };
                state.loading = false;
            })
            .addCase(getCustomerPayement.rejected, (state) => {
                state.loading = false;
            });
        // add cases for other api calls
        // like builder.addCase

    },
});

export const {
    setPage,
    setPageSize,
    setSearch,
    setOrdering,
    addFilterRule,
    removeFilterRule,
    updateFilterRule,
    applyFilter,
    clearFilter,
    applyList
} = customerPaymentsSlice.actions;

export default customerPaymentsSlice.reducer;

