import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "../hooks/useAuth";
import { Grid, IconButton, styled } from "@mui/material";
import AppHeader from "../components/AppHeader";
import Sidebar from "../components/Sidebar";
import LayoutStyle from "./LayoutStyle";
import { logout as authLogout } from "../store/reducers/authReducer";
import { ChevronLeft } from "../assets/svg";

const PrivateLayout = ({ children, ...props }) => {
  const { className } = props;
  const { user, logout } = useAuth();
  const dispatch = useDispatch();

  /**
   * Define variables
   */
  const [sideBarOpen, setSideBarOpen] = useState(true);


  const onLogout = () => {
    dispatch(authLogout());
    logout();
  };

  return (
    <Grid container className={`${className} privateLayout`}>
      <AppHeader logout={onLogout} />
      <Grid container className="bodyWrapper">
        <Grid item className={`sideBarRoot ${sideBarOpen ? 'opened' : 'closed'}`} sx={{ width: sideBarOpen ? '280px' : '70px' }}>
          <Sidebar permissions={user?.permissions} />
          <IconButton className="handleSidebarBtn" onClick={() => { setSideBarOpen(!sideBarOpen) }}>
            <ChevronLeft />
          </IconButton>
        </Grid>

        <Grid item className="bodyContainer">
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default styled(PrivateLayout)(LayoutStyle);
