import React, { useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel, Grid, IconButton, Popover, Slider, Typography, styled } from "@mui/material";
import { CloseIcon, FilterRefresh } from "../../assets/svg";

import Style from "./style";
const Status = (props) => {

  const { options, handleFilter, filter } = props;
  const [anchorEl, setAnchorEl] = useState(null)
  const [data, setData] = useState(filter?.Status)
  const open = Boolean(anchorEl);
  const title = options?.title
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setData(filter?.Status)
  }, [filter?.Status])

  return (
    <Grid className="ml-2" >
      <Grid onClick={(event) => { setAnchorEl(event.currentTarget) }} className="filterBox">
        <FilterRefresh />
        <Grid className="dflex">
          <Typography className="pl5 secondary1">{title} :</Typography>
          <Typography className="pl5 primary1">{filter?.Status[0]}  {filter?.Status.length > 1 ? <span style={{ background: "#2563EB", color: "white", fontSize: 12, padding: "3px 5px", borderRadius: 3 }}> +{`${(filter?.Status.length) - 1}`} </span> : ""}</Typography>
        </Grid>
      </Grid>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        className="mt-1"
      >
        <Typography className="p-1 pt-2 fw-600">By Status</Typography>
        {options?.option?.map((option) => (
          <Grid className="pl-1" style={{ minWidth: "200px" }}>
            <FormControlLabel
              onClick={(e) => {
                if (e.target.checked) {

                  setData([...data, option.value])
                } else {
                  setData(data?.filter(u => u !== option.value))
                }
              }}
              control={
                <Checkbox
                  name={"status"}
                  checked={data?.filter(u => u === option.value).length > 0 ? true : false}
                />
              }
              label={option.value}
            />
          </Grid>
        ))}
        <Grid className="p-1"><Button fullWidth variant="contained" color="primary" onClick={() => {
          handleFilter({ data, title: options.title })
          handleClose()
        }}>Apply</Button></Grid>
      </Popover>

    </Grid >
  )
}

const RangeSlider = (props) => {

  const { options, handleFilter, filter } = props
  const [sliderValue, setSliderValue] = useState(filter?.Price);
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl);
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const title = options?.title
  const handleSliderChange = (newValue) => {
    setSliderValue(newValue);
  }

  return (
    <Grid>
      <Grid onClick={(event) => { setAnchorEl(event.currentTarget) }} className="filterBox">
        <FilterRefresh />
        <Grid className="dflex aCenter">
          <Typography className="pl5" style={{ color: "#64748B" }} variant='body1'>{title} : </Typography>
          <Typography className="pl5 primary1">{filter?.Price[0]}$ - {filter?.Price[1]}$</Typography>
        </Grid>
      </Grid>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          handleClose1()
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography className="pt-1 pl-1 fw-600 ">Select Price</Typography>
        <Grid style={{ width: "300px" }} className="p-2">
          <Slider
            valueLabelDisplay="auto"
            value={sliderValue}
            aria-labelledby="range-slider"
            min={0}
            max={100}
            onChange={(event, value) => {
              handleSliderChange(value)
            }}
          />
          <Button onClick={() => {
            handleFilter({ data: sliderValue, title: title })
            handleClose1()
          }} fullWidth variant="contained" color="primary">Apply</Button>
        </Grid>
      </Popover>
    </Grid>

  );
};

const Filter = (props) => {
  const { options, handleFilter, removeFilter, className, setFilters, appliedFilter } = props;
  const MAP_FILTER_COMP = {
    checkbox: Status,
    price: RangeSlider
  }
  return (
    <Grid className={className}>
      <Grid className="dflex aCenter">
        <Typography style={{ color: "#64748B" }}>Filters</Typography>
        {options?.map((option) => {
          const Comp = MAP_FILTER_COMP[option?.type]
          return (

            <Comp options={option} handleFilter={handleFilter} filter={appliedFilter} />
          )
        })}

      </Grid>
      <Grid className="dflex aCenter cursorPointer" onClick={() => {
        removeFilter()
        setFilters(false)

      }}>
        <Typography className="pl-1 pr-2"> <IconButton> <CloseIcon /> </IconButton>Clear Filter</Typography>
      </Grid>
    </Grid>
  )
};

export default styled(Filter)(Style);;
