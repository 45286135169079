import React from "react";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import AgencyDetail from "../containers/Agencies/AgencyDetail";
import Sales from "../containers/Sales";
import { Grid, Typography } from "@mui/material";

const salesRoutes = [
    {
        path: '/sales',
        element: <ProtectedRoute />,
        children: [
            {
                path: '',
                element: <Grid className="dflex jCenter aCenter h-100">
                    <Typography variant="h1" style={{ fontSize: "55px" }}>
                        Coming Soon...
                    </Typography>
                </Grid>
            },

            {
                path: 'customerpayments',
                element: <Sales />
            },
            {
                path: ':agency_id',
                element: <AgencyDetail />
            },

        ]
    }
]

export default salesRoutes
