import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  styled,
} from "@mui/material";

import {
  setPageSize,
  setPage,
  setSearch,
  setOrdering,
} from "../../store/reducers/userManagementReducer";
import Style from "./style";

//import UserCard from './Card'
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../store/actions/userAction";
import Table from "../../components/Table";
import { useNavigate, useLocation } from "react-router-dom";
// import { MoreOption } from "../../assets/svg";

const Users = (props) => {
  const { className } = props;
  const { loading, list } = useSelector((state) => state.userManagement);
  const { pagination, ordering, orderBy, filter } = list;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const search = list.search || queryParams.get('searchText') || '';
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    let keysearch = "";
    let filters = { ordering }
    const queryParams = `?page_size=${pagination?.page_size}&page=${pagination?.page_number}${keysearch}`;
    if (search) {
      keysearch = `&search=${search}`;
    }

    dispatch(getUser({ queryParams, filters }));
  }, [
    dispatch,
    pagination?.page_size,
    pagination?.page_number,
    search,
    ordering,
    filter.params,
  ]);


  const handlePageSize = (e) => {
    dispatch(setPageSize(e.target.value));
  };

  const handlePagination = (e, pageValue) => {
    dispatch(setPage(pageValue));
  };

  const handleSearch = (searchVal) => {
    dispatch(setSearch(searchVal));
  };

  const onSort = (sortBy) => {
    dispatch(setOrdering(sortBy));
  };



  const Action = (props) => {

    const { rowData } = props;
    // const [anchorEl, setAnchorEl] = useState(null)

    // const open = Boolean(anchorEl);

    // const handleClose = () => {
    //   setAnchorEl(null)
    // }

    // const handleClick = (event) => {
    //   setAnchorEl(event.currentTarget);
    // };
    return (<Button className="moreOption" onClick={() => { navigate(`profile/${rowData.id}`) }}>view</Button>
      // <>
      //   <IconButton onClick={handleClick}><MoreOption /></IconButton>

      //   {
      //     <Popover
      //       open={open}
      //       anchorEl={anchorEl}
      //       onClose={handleClose}
      //       anchorOrigin={{
      //         vertical: "bottom",
      //         horizontal: "left",
      //       }}
      //     >
      //       <Grid className="p-1" onClick={() => {
      //         navigate(`profile/${rowData.id}`)
      //       }}>
      //         <Typography>View Detail</Typography>
      //       </Grid>
      //     </Popover>
      //   }
      // </>
    )
  }

  const cellMap = {
    action: Action,

  };

  return (
    <Grid
      container
      className={className}
    >
      <div className={`table h-100`}>
        <Table
          title={"User List"}
          headers={[...(list?.headers || []), { field: 'action', label: 'action' }]}
          data={list?.results || []}
          cellMap={cellMap}
          search={search}
          pagination={pagination}
          handlePagination={handlePagination}
          handlePageSize={handlePageSize}
          handleSearch={handleSearch}
          ordering={ordering}
          orderBy={orderBy || []}
          onSort={onSort}
          addUser={true}
        />
        <div>{loading ? "Loading..." : ""}</div>
      </div>
    </Grid>

  );
};

// default props
Users.defaultProps = {
  classes: {}
};

// prop types
Users.propTypes = {
  classes: PropTypes.object
};


export default styled(Users)(Style);
