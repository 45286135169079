import React, { useEffect, useState, useRef } from "react";
import {
    Grid,
    InputAdornment,

    Popover,
    TextField,

    styled,
} from "@mui/material";
import Style from "./style";
import { SearchIcon } from "../../assets/svg";
import ShowResults from "./showResults";


const SearchSuggestionComponent = (props) => {
    const { className } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchWidth, setSearchWidth] = useState(0);
    const [searchText1, setSearchText1] = useState("");
    const fieldRef = useRef(null);


    useEffect(() => {
        if (fieldRef.current) {
            setSearchWidth(fieldRef.current.getBoundingClientRect().width);
        }
    }, []);

    const handleSearchChange = (event) => {
        setSearchText1(event.target.value);
    };

    const handleSearchClick = (event) => {
        if (searchText1.trim() !== "") {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAllResults = () => {
        setSearchText1("");
        setAnchorEl(null); // Close the popover
    };


    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <Grid
            container
            justifyContent={"flex-start"}
            alignItems={"center"}
            className={`${className} search-container`}
        >
            <Grid className="searchHeader w-100">
                <TextField
                    ref={fieldRef}
                    className="w-100"
                    placeholder="Search..."
                    id="outlined-basic"
                    variant="outlined"
                    size="medium"
                    value={searchText1}
                    onChange={handleSearchChange}
                    onClick={handleSearchClick}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                    }}
                />
            </Grid>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                className={className}
                sx={{ '& .MuiPaper-root': { width: searchWidth } }}
            >
                <Grid>
                    <ShowResults
                        searchText={searchText1}
                        inputref={fieldRef}
                        searchSuggestion
                        handleAllResults={handleAllResults}
                    />
                </Grid>
            </Popover>
        </Grid>
    );
};

const SearchSuggestion = styled(SearchSuggestionComponent)(({ theme }) => Style(theme));

export default SearchSuggestion;
