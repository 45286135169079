const DialogStyles = (theme) => ({
    padding: "25px 10px",
    '.description': {
        fontWeight: 600,
        fontSize: 16,
        justifyContent: "center",
        display: "flex"
    },
    '.button': {
        justifyContent: "center",
        display: "flex",
        marginTop: 10,
        '.btn': {
            padding: "3px 20px"
        },
        '.confirm': {
            background: "#0000ff",
            color: "white"
        },
        '.cancel': {
            background: "#808080",
            color: "white",
            marginLeft: 5
        }
    }
})

export default DialogStyles