import { createHashRouter } from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import homeRoutes from "./homeRoutes";
import loginRoutes from "./loginRoutes";
import agenciesRoutes from "./agenciesRoutes";
import salesRoutes from "./salesRoutes";
import userRoutes from "./userRoutes";
import invoicesRoutes from "./invoicesRoutes";
import searchRoutes from "./searchRoutes";
import showResultsRoutes from "./showResultRoutes";
import EmailRoutes from "./emailroutes";
import SettingsRoutes from "./settingsRoutes";

/**
 * Main Routes for the Application
 */
const router = createHashRouter([
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      ...loginRoutes,
      ...homeRoutes,
      ...agenciesRoutes,
      ...salesRoutes,
      ...userRoutes,
      ...invoicesRoutes,
      ...searchRoutes,
      ...showResultsRoutes,
      ...EmailRoutes,
      ...SettingsRoutes,
    ],
  },
]);

export default router;
