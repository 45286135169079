import React from "react";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import Invoices from "../containers/Invoices";
import InvoiceDetail from "../containers/Invoices/Deatil";

const invoicesRoutes = [
  {
    path: "/invoices",
    element: <ProtectedRoute />,
    children: [
      {
        path: "",
        element: <Invoices />,
      },
      {
        path: "detail/:id",
        element: <InvoiceDetail />,
      },
    ],
  },
];

export default invoicesRoutes;
