import { postRequest } from "../../utils/apiHelper";

export const agenciesService = {
  getAgencies,
  getAgenciesDetail,
  getAgenciPayment,
  getAgenciesClient,
  getAgencyFlows,
  getCustomerPayement,
  getUser,
  getProjectClients,
  getUserInfo,
  getInvoice,
  getProjectTeams,
  reset,
  getInvoiceDetail
};

async function getAgencies(data) {
  return await postRequest(`agency/${data?.queryParams}`, data.filters);
}

async function getAgenciesDetail(queryParams = "") {
  return await postRequest(`agency/agenciesdetail/${queryParams}`);
}

async function getAgenciPayment(data) {
  return await postRequest(`agency/agencypayment/${data?.queryParams}`, data.filters);
}

async function getAgenciesClient(queryParams = "") {
  return await postRequest(`agency/agencyclients/${queryParams}`)
}

async function getAgencyFlows(queryParams = "") {
  return await postRequest(`agency/agencyflows/${queryParams}`)
}

async function getCustomerPayement(data) {
  return await postRequest(`agency/customerpayments/${data?.queryParams}`, data?.filters);
}

async function getUser(data) {
  return await postRequest(`agency/users/${data?.queryParams}`, data.filters);
}

async function getProjectClients(queryParams) {
  return await postRequest(`agency/clients/${queryParams}`)
}

async function getProjectTeams(queryParams) {
  return await postRequest(`agency/teams/${queryParams}`)
}

async function getUserInfo(queryParams = "") {
  return await postRequest(`agency/user/${queryParams}`);
}

async function getInvoiceDetail(queryParams = "") {
  return await postRequest(`agency/invoiceDetail/${queryParams}`);
}

async function getInvoice(data) {
  console.log(data?.queryParams, "8888")
  return await postRequest(`agency/invoice/${data?.queryParams}`, data.filters);
}
async function reset(email) {
  return await postRequest('resetpass', { email });
}