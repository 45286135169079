import theme from "../../../theme";

const Style = () => ({
    height: "100%",
    padding: 30,
    '.profileContainer': {
        padding: '24px',
        borderRadius: '12px',
        background: theme.palette.containers.card,
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.06)',
        '& .MuiInputAdornment-root': {
            '& .MuiAvatar-root': {
                width: '30px',
                height: '30px'
            }
        },
        '& .MuiDivider-root': {
            borderColor: theme.palette.grey.border
        }
    },
    '.profileImgContainer': {
        height: '108px',
        width: '108px',
        position: 'relative',
        '& .uploadBtn': {
            height: '40px',
            width: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '100px',
            boxShadow: '4px 8px 18px 0px rgba(37, 99, 235, 0.30)',
            background: theme.palette.containers.blueBg,
            position: 'absolute',
            right: '5px',
            bottom: '-8px',
            zIndex: 1
        },
        '& .MuiAvatar-root': {
            width: '100%',
            height: '100%',
            border: `3px solid ${theme.palette.containers.greyBg1}`,
            borderRadius: '100%'
        }
    }
})

export default Style;