import { createSlice } from "@reduxjs/toolkit";
import { getUserInfo, resetPasword } from "../actions/agenciesAction";



const initialState = {
    list: {
        userInfo: [],
        reset: "",
        ownedAgencies: [],
        workspace: [],

    },
};

export const userInfoSlice = createSlice({
    name: "UsersInfo",
    initialState,
    extraReducers: (builder) => {
        // add cases for getUserInfo api calls
        builder
            .addCase(getUserInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserInfo.fulfilled, (state, { payload }) => {
                state.list = {
                    ...state.list,
                    ...payload,
                    reset: ""
                };
                state.loading = false;
            })
            .addCase(getUserInfo.rejected, (state) => {
                state.loading = false;
            });
        builder
            .addCase(resetPasword.pending, (state) => {
                state.loading = true;

            })
            .addCase(resetPasword.fulfilled, (state, { payload }) => {
                state.list = {
                    ...state.list,
                    reset: payload
                };
                state.loading = false;

            })
            .addCase(resetPasword.rejected, (state) => {
                state.loading = false;
            });
        // add cases for other api calls
        // like builder.addCase

    },
});


export default userInfoSlice.reducer;
