import React, { useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
  styled,
  List
} from "@mui/material";
import Style from "./style";
import { BellIcon, FlozyLogo } from "../../assets/svg";
import SearchSuggestion from "./suggestion";
// import Search from "../../containers/Search";

const AppHeaderComponent = (props) => {
  const { logout, className } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  //const [searchOpen, setSearchOpen] = useState(false);

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAvatarClose = () => {
    setAnchorEl(null);
  };

  // const handleSearchClick = () => {
  //   setSearchOpen((prev) => !prev);
  // };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Grid
      container
      justifyContent={"space-between"}
      alignItems={"center"}
      className={`${className} header`}
      wrap="nowrap"
    >
      <Grid item sx={{ pr: { md: 8 } }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FlozyLogo />
          <Typography variant="h4" sx={{ ml: 1, mr: 10 }} color={"text.primaryText"}>
          </Typography>
          {/* <IconButton onClick={handleSearchClick}>
            <SearchIcon />
          </IconButton>
          {searchOpen && <Search />} */}
        </Box>
      </Grid>
      <Grid item className="flexAlign flexOne">
        <SearchSuggestion />
        {/* <IconButton>
          <SearchIcon />
        </IconButton> */}
        <IconButton>
          <BellIcon />
        </IconButton>
        <IconButton onClick={handleAvatarClick}>
          <Avatar />
        </IconButton>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleAvatarClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={logout}>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
          </List>
        </Popover>
      </Grid>
    </Grid>
  );
};

const AppHeader = styled(AppHeaderComponent)(({ theme }) => Style(theme));

export default AppHeader;
