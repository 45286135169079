import React, { Suspense } from "react";
import { useOutlet, useLocation, Link } from "react-router-dom";
import Loader from "../components/Loader";
import AgencyDetail from "../containers/Agencies/AgencyDetail";
import { ArrowIcon } from "../assets/svg";

const getBreadcrumbs = (path, ageid) => {
    const breadCrumbs = {
        "/agencies": [
            { label: "Agency", route: `/agencies` }
        ],
        [`/agencies/${ageid}`]: [
            { label: "Agency", route: `/agencies` },
            { label: "Agency Info", route: `/agencies/${ageid}` }
        ],
        [`/agencies/${ageid}/clients`]: [
            { label: "Agency", route: `/agencies` },
            { label: "Agency Info", route: `/agencies/${ageid}` },
            { label: "Clients", route: `/agencies/${ageid}/clients` }
        ],
        [`/agencies/${ageid}/flows`]: [
            { label: "Agency", route: `/agencies` },
            { label: "Agency Info", route: `/agencies/${ageid}` },
            { label: "Flows", route: `/agencies/${ageid}/flows` }
        ],
        [`/agencies/${ageid}/invoice`]: [
            { label: "Agency", route: `/agencies` },
            { label: "Agency Info", route: `/agencies/${ageid}` },
            { label: "Invoice", route: `/agencies/${ageid}/invoice` }
        ],
        [`/agencies/${ageid}/invitation`]: [
            { label: "Agency", route: `/agencies` },
            { label: "Agency Info", route: `/agencies/${ageid}` },
            { label: "Invitation", route: `/agencies/${ageid}/invitation` }
        ]
    };

    return breadCrumbs[path] || [];
};

const AgencyLayout = () => {
    const outlet = useOutlet();
    const location = useLocation();

    // Extract agency id from the current path
    const pathSegments = location.pathname.split("/");
    const ageid = pathSegments[2];

    const breadcrumbs = getBreadcrumbs(location.pathname, ageid);

    return (
        <div>
            <nav className="breadcrumb" style={{ paddingLeft: "32px", marginTop: "20px" }}>
                {breadcrumbs.map((breadcrumb, index) => (
                    <span key={index} className="breadcrumb-item" style={{ color: "black" }}>
                        <Link to={breadcrumb.route} className="breadcrumb-link" style={{ fontWeight: breadcrumb.route === location.pathname ? "bold" : "bold", textDecoration: "none", color: breadcrumb.route === location.pathname ? "black" : "rgb(185 189 204)" }}>
                            {breadcrumb.label}
                        </Link>
                        {index < breadcrumbs.length - 1 && (
                            <span className="breadcrumb-separator"> <ArrowIcon /> </span>
                        )}
                    </span>
                ))}
            </nav>
            <AgencyDetail />
            <Suspense fallback={<Loader open={"Loading Files"} />}>{outlet}</Suspense>
        </div >
    );
};

export default AgencyLayout;
