import { Grid, Typography, styled, Avatar } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Table from "../../../components/Table";
import Style from "../Style";
import { getAgenciesFlows } from "../../../store/actions/agenciesAction";
import {
    applyFilter,
    clearFilter,
    setOrdering,
    setPage,
    setPageSize,
    setSearch,
} from "../../../store/reducers/agenciesFlowsReducer";

const AgencyFlows = (props) => {
    const { className } = props;
    const dispatch = useDispatch();
    let { agency_id } = useParams();
    console.log("agency_id1111", agency_id);

    const { list } = useSelector((state) => state.agenciesFlows);
    const { pagination, search, ordering, orderBy, filter, orderDirection } =
        list;

    const UserAvatarName = (props) => {
        const { rowData } = props;
        return (
            <Grid container alignItems="center">
                {rowData?.avatar_filename ? (
                    <img
                        src={`path_to_your_assets/${rowData?.avatar_filename}`}
                        height="30px"
                        width="30px"
                        style={{ borderRadius: "100%" }}
                        alt=""
                    />
                ) : (
                    <Avatar style={{ height: "30px", width: "30px" }} />
                )}
                <Typography className="pl-1" style={{ color: "#0F172A" }}>
                    {rowData?.username || '-'}
                </Typography>
            </Grid>
        );
    };

    const getAgenciesFlowsDetails = (
        page_size,
        page_number,
        search,
        ordering,
        orderDirection
    ) => {
        let queryParams = "?";

        queryParams += `page_size=${page_size}`;

        queryParams += `&page=${page_number}`;

        if (search) {
            queryParams += `&search=${search}`;
        }

        if (ordering) queryParams += `&sortField=${ordering.field}&sortOrder=${ordering.sort ? 'asc' : 'desc'}`;

        dispatch(getAgenciesFlows(`${agency_id}${queryParams}`));
    };

    const handlePageSize = (e) => {
        getAgenciesFlowsDetails(
            e.target.value,
            pagination.page_number,
            search,
            ordering,
            orderDirection
        );
        dispatch(setPageSize(e.target.value));
    };

    const handlePagination = (e, pageValue) => {
        getAgenciesFlowsDetails(pagination.page_size, pageValue, search, ordering);
        dispatch(setPage(pageValue));
    };

    const handleSearch = (searchVal) => {
        getAgenciesFlowsDetails(
            pagination.page_size,
            pagination.page_number,
            searchVal,
            ordering,
            orderDirection
        );
        dispatch(setSearch(searchVal));
    };

    // const onSort = (sortBy) => {
    //     const currentOrderDirection =
    //         ordering === sortBy && orderBy === "asc" ? "desc" : "asc";
    //     getAgenciesFlowsDetails(
    //         pagination.page_size,
    //         pagination.page_number,
    //         search,
    //         sortBy,
    //         currentOrderDirection,
    //         orderDirection
    //     );
    //     dispatch(setOrdering(sortBy));
    //     dispatch(setOrderDirection(currentOrderDirection));
    // };

    const onSort = (sortBy) => {
        getAgenciesFlowsDetails(
            pagination.page_size,
            pagination.page_number,
            search,
            sortBy
        );
        dispatch(setOrdering(sortBy));
    };

    const handleFilter = (data) => {

        const prevFilter = {
            ...filter,
            [data.title]: data.data
        };

        getAgenciesFlowsDetails(
            pagination.page_size,
            pagination.page_number,
            search,
            ordering,
            prevFilter
        );
        dispatch(applyFilter(data))
    }

    const removeFilter = () => {
        dispatch(clearFilter())
    }


    useEffect(() => {
        getAgenciesFlowsDetails(
            pagination.page_size,
            pagination.page_number,
            search,
            ordering
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agency_id]);

    const headers = [
        { field: "job_title", label: "Flow Name", sort: true },
        { field: "job_due_date", label: "Due Date", sort: true },
        { field: "created_by", label: "Created By", sort: true },
        { field: "status", label: "Status", sort: true },
    ];

    const getStatusLabel = (status) => {
        switch (status) {
            case 0:
                return "In Progress";
            case 1:
                return "Completed";
            default:
                return "Unknown";
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 0:
                return { color: "#FD7E01", fontWeight: "500" };
            case 1:
                return { color: "#0E8E2A", fontWeight: "500" };
            default:
                return "grey";
        }
    };

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return new Intl.DateTimeFormat("en-GB", {
            day: "2-digit",
            month: "long",
            year: "numeric",
        }).format(date);
    };

    return (
        <Grid container className={className}>
            <Grid className="dflex">
                <Table
                    title={"Agency Flows"}
                    headers={headers}
                    data={
                        list?.results.map((item) => ({
                            ...item,
                            job_title: (
                                <Typography style={{ fontWeight: "bold", color: "black" }}>
                                    {item.job_title}
                                </Typography>
                            ),
                            job_due_date: formatDate(item.job_due_date),
                            status: (
                                <span style={getStatusColor(item.status)}>
                                    {getStatusLabel(item.status)}
                                </span>
                            ),
                            created_by: <UserAvatarName rowData={item} />,
                        })) || []
                    }
                    search={search}
                    pagination={pagination}
                    handlePagination={handlePagination}
                    handlePageSize={handlePageSize}
                    handleSearch={handleSearch}
                    ordering={ordering}
                    orderBy={orderBy || []}
                    onSort={onSort}
                    filter={true}
                    handleFilter={handleFilter}
                    removeFilter={removeFilter}
                />
                {/* <Grid>{loading ? "Loading..." : ""}</Grid> */}
            </Grid>
        </Grid>
    );
};

AgencyFlows.defaultProps = {};

AgencyFlows.propTypes = {};

export default styled(AgencyFlows)(Style);