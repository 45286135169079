import theme from "../../../theme";

const Style = () => ({
    '& .userdetailContainer': {
        padding: '24px'
    },
    '& .agencyDetails': {
        borderRadius: '12px',
        padding: '14px',
        background: theme.palette.containers.greyBg5
    },
    '& .agencyRoleList': {
        border:  `1px solid ${theme.palette.primary.main}`,
        boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.06)',
        padding: '24px',
        borderRadius: '12px'
    },
    '& .otherAgenciesHeader': {
        paddingBottom: '10px'
      },
      '& .otherAgenciesItem': {
        padding: '8px 0px',
      },
      '& .userRoleSelect': {
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
        '& .MuiSelect-select': {
            color: theme.palette.text.greyText1
        }
      }
});

export default Style;
