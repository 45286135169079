import { styled } from "@mui/material/styles";
import {
    Table as MuiTable,
    TableContainer as MuiTableContainer,
    TableHead as MuiTableHead,
    TableRow as MuiTableRow,
    TableCell as MuiTableCell,
    TableBody as MuiTableBody,
    Typography as MuiTypography,
    Button as MuiButton,
} from "@mui/material";

export const TableContainer = styled(MuiTableContainer)(({ theme }) => ({
    maxHeight: 400,
    background: "#FFF",
    borderRadius: "12px",
    zIndex: 1,
    ".MuiTableContainer-root": {},
    ".MuiTableHead-root": {
        background: "#F8FAFC",
        borderRadius: "12px",
        "& .MuiTableCell-head": {
            borderTopLeftRadius: "12px",
            borderBottomLeftRadius: "12px",
        },
        "& .MuiTableCell-head:last-child": {
            borderTopRightRadius: "12px",
            borderBottomRightRadius: "12px",
        },
    },
    ".MuiTableCell-head": {
        borderBottom: "none",
        "& .MuiTypography-root": {
            textTransform: "uppercase",
        },
    },
    ".MuiTableCell-body": {
        borderBottom: "1px solid #F2F0F0",
    },
}));

export const Table = styled(MuiTable)(({ theme }) => ({
    minWidth: 650,
}));

export const TableRow = styled(MuiTableRow)(({ theme }) => ({
    "& > *": { padding: "8px" },
}));

export const TableHead = styled(MuiTableHead)(({ theme }) => ({
    background: "#F8FAFC",
    borderRadius: "12px",
}));

export const TableBody = styled(MuiTableBody)(({ theme }) => ({
    ".MuiTableCell-body": {
        borderBottom: "1px solid #F2F0F0",
    },
}));

export const TableCell = styled(MuiTableCell)(({ theme }) => ({
    "&.MuiTableCell-head": {
        borderBottom: "none",
        fontWeight: theme.typography.fontWeightBold,
        "& .MuiTypography-root": {
            textTransform: "uppercase",
        },
    },
    "&.MuiTableCell-body": {
        borderBottom: "1px solid #F2F0F0",
    },
}));

export const Typography = styled(MuiTypography)(({ theme }) => ({
    margin: theme.spacing(2, 0),
    "&.search-loading": {
        marginLeft: theme.spacing(2),
    },
    "&.section-title": {
        marginBottom: theme.spacing(2),
    },
}));

export const Button = styled(MuiButton)(({ theme }) => ({
    marginLeft: theme.spacing(2),
}));
