import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { searchAgencies } from '../../store/actions/searchAction';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from './style'; // Import styled components
import { InvoiceIcon, ArrowIcon } from "../../assets/svg/index";
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const highlightText = (text, query) => {
    if (typeof text !== 'string') return text; // Ensure text is a string
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, index) =>
        part.toLowerCase() === query.toLowerCase() ? (
            <span key={index} style={{ fontWeight: 'bold', color: '#000000' }}>{part}</span>
        ) : (
            part
        )
    );
};

const getStatusStyles = (status) => {
    switch (status) {
        case 1: // Draft
            return {
                backgroundColor: '#EDEDED',
                color: '#64748B',
                padding: '6px 12px',
                fontSize: '1rem'
            };
        case 2: // Sent
            return {
                backgroundColor: '#FFFFCC',
                color: '#FFD700',
                padding: '6px 12px',
                fontSize: '1rem'
            };
        case 3: // Seen
            return {
                backgroundColor: '#D6EAF8',
                color: '#3498DB',
                padding: '6px 12px',
                fontSize: '1rem'
            };
        case 4: // Paid
            return {
                backgroundColor: '#D4F6D2',
                color: '#0E8E2A',
                padding: '6px 12px',
                fontSize: '1rem'
            };
        case 5: // Cancelled
            return {
                backgroundColor: '#FFDFDD',
                color: '#FF3B3B',
                padding: '6px 12px',
                fontSize: '1rem'
            };
        case 6: // Refunded
            return {
                backgroundColor: '#FFE1F0',
                color: '#FF5EB0',
                padding: '6px 12px',
                fontSize: '1rem'
            };
        default:
            return {
                padding: '4px 8px',
                fontSize: '1rem'
            };
    }
};

const getStatusText = (statusCode) => {
    switch (statusCode) {
        case 1:
            return 'Draft';
        case 2:
            return 'Sent';
        case 3:
            return 'Seen';
        case 4:
            return 'Paid';
        case 5:
            return 'Cancelled';
        case 6:
            return 'Refunded';
        default:
            return 'Unknown';
    }
};

const CustomButton = ({ path, searchText }) => (
    <Link to={`/${path}?query=${encodeURIComponent(searchText)}`} style={{ textDecoration: 'none' }}>
        <Button
            variant="outlined"
            style={{
                backgroundColor: 'white',
                color: '#2563EB',
                border: '1px solid #2563EB',
                '&:hover': {
                    backgroundColor: '#2563EB',
                    color: 'white',
                },
            }}
        >
            <Typography variant="body1" style={{ fontWeight: 'normal' }}>View More</Typography>
            <ArrowIcon style={{ marginLeft: '4px' }} />
        </Button>
    </Link>
);

const SearchResults = () => {
    const dispatch = useDispatch();
    const query = useQuery();
    const searchText = query.get('query');
    const TB_agencies = useSelector((state) => state.search.agencies.data);
    const TB_users = useSelector((state) => state.search.users.data);
    const TB_invoice = useSelector((state) => state.search.invoices.data);
    const loading = useSelector((state) => state.search.loading);

    useEffect(() => {
        if (searchText && searchText.length > 1) {
            dispatch(searchAgencies(searchText));
        }
    }, [dispatch, searchText]);

    if (!searchText) {
        return null; // If no query, render nothing
    }
    const formatDate = (dateString) => {
        if (!dateString) return ""; // Handle empty or null values
        console.log("date", dateString)
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return ""; // Invalid date, handle appropriately
        }

        return format(date, 'd MMM yyyy');
    };


    return (
        <div style={{ padding: '16px', textAlign: 'center', background: 'white' }}>
            {loading ? (
                <Typography variant="body2" sx={{ ml: 2 }}>
                    Searching...
                </Typography>
            ) : (
                <>
                    <Typography variant="body2" sx={{ fontWeight: 600, fontSize: '14px', fontFamily: 'Inter', textAlign: 'left', paddingLeft: '20px', marginBottom: '8px' }}>
                        <span style={{ fontWeight: 400, color: '#94A3B8' }}>Showing results for</span> "{searchText}"
                    </Typography>
                    {TB_invoice && TB_invoice.length > 0 && (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0px', paddingTop: '20px' }}>
                                <Typography variant="h6" sx={{ color: '#2563EB', backgroundColor: '#D6E6FF', padding: '4px', borderRadius: '4px', width: '150px' }}>
                                    Invoice
                                </Typography>
                                <CustomButton path="agencies/invoice" searchText={searchText} />

                            </div>
                            <TableContainer>
                                <Table sx={{ "& .MuiTableCell-body": { padding: "10px", color: "#64748BE5" }, "& .MuiTableCell-root": { borderRadius: 0, alignItems: 'center' } }}>
                                    <TableHead>
                                        <TableRow sx={{ "& th": { fontWeight: "bold", bgcolor: "white" } }}>
                                            <TableCell>Invoice No</TableCell>

                                            <TableCell>Email</TableCell>
                                            <TableCell>Currency</TableCell>
                                            <TableCell>Client</TableCell>
                                            <TableCell>Amount</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell align='right'>Due Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {TB_invoice.map((user) => (
                                            <TableRow key={user.id}>
                                                <TableCell>
                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                        <InvoiceIcon style={{ marginRight: '8px' }} />
                                                        {highlightText(`#${user.invoice_number}`, searchText)}
                                                    </span>
                                                </TableCell>
                                                <TableCell>{highlightText(user.customer_email, searchText)}</TableCell>
                                                <TableCell>{highlightText(user.invoice_currency, searchText)}</TableCell>
                                                <TableCell>{highlightText(user.customer_name, searchText)}</TableCell>
                                                <TableCell>{highlightText(`$${user.invoice_total}`, searchText)}</TableCell>
                                                <TableCell>
                                                    <span style={getStatusStyles(user.status)}>
                                                        {highlightText(getStatusText(user.status), searchText)}
                                                    </span>
                                                </TableCell>
                                                <TableCell align='right'>{highlightText(formatDate(user.updated_at), searchText)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}
                    {TB_agencies && TB_agencies.length > 0 && (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0px', paddingTop: '20px' }}>
                                <Typography variant="h6" sx={{ color: '#2563EB', backgroundColor: '#D6E6FF', padding: '4px', borderRadius: '4px', width: '100px' }}>
                                    WorkSpace
                                </Typography>
                                <CustomButton path="agencies/list" searchText={searchText} />
                            </div>
                            <TableContainer>
                                <Table sx={{ "& .MuiTableCell-body": { padding: "10px", color: "#64748BE5" }, "& .MuiTableCell-root": { borderRadius: 0, alignItems: 'center' } }}>
                                    <TableHead>
                                        <TableRow sx={{ "& th": { fontWeight: "bold", bgcolor: "white" } }}>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell align='right'>Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {TB_agencies.map((result) => (
                                            <TableRow key={result.id}>
                                                <TableCell>
                                                    <img src={result.agency_logo} alt="Agency Logo" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                                                    {highlightText(result.agency_name, searchText)}
                                                </TableCell>
                                                <TableCell>{highlightText(result.agency_email, searchText)}</TableCell>
                                                <TableCell align='right'>{highlightText(result.last_paid, searchText)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}
                    {TB_users && TB_users.length > 0 && (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0px', paddingTop: '20px' }}>
                                <Typography variant="h6" sx={{ color: '#2563EB', backgroundColor: '#D6E6FF', padding: '4px', borderRadius: '4px', width: '100px' }}>
                                    Users
                                </Typography>
                                <CustomButton path="agencies/users" searchText={searchText} />
                            </div>
                            <TableContainer>
                                <Table sx={{ "& .MuiTableCell-body": { padding: "10px", color: "#64748BE5" }, "& .MuiTableCell-root": { borderRadius: 0, alignItems: 'center' } }}>
                                    <TableHead>
                                        <TableRow sx={{ "& th": { fontWeight: "bold", bgcolor: "white" } }}>
                                            <TableCell>First Name</TableCell>
                                            <TableCell>Last Name</TableCell>
                                            <TableCell>User Name</TableCell>
                                            <TableCell align='right'>Email</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {TB_users.map((user) => (
                                            <TableRow key={user.id}>
                                                <TableCell>{highlightText(user.first_name, searchText)}</TableCell>
                                                <TableCell>{highlightText(user.last_name, searchText)}</TableCell>
                                                <TableCell>{"@" + highlightText(user.username, searchText)}</TableCell>
                                                <TableCell align='right'>{highlightText(user.email, searchText)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}
                    {!loading && ((!TB_agencies || TB_agencies.length === 0) && (!TB_users || TB_users.length === 0)) && (
                        <Typography variant="h3" sx={{ mt: 4, fontWeight: 'bold', fontSize: '32px', color: '#888' }}>
                            No data available
                        </Typography>
                    )}
                </>
            )
            }
        </div >
    );
};

export default SearchResults;
