import React from "react";
import { ProtectedRoute } from "../hoc/ProtectedRoute";

import { Grid, Typography } from "@mui/material";

const EmailRoutes = [
    {
        path: '/email',
        element: <ProtectedRoute />,
        children: [
            {
                path: '',
                element: <Grid className="dflex jCenter aCenter h-100">
                    <Typography variant="h1" style={{ fontSize: "55px" }}>
                        Coming Soon...
                    </Typography>
                </Grid>
            },



        ]
    }
]

export default EmailRoutes
