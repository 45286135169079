import React from "react";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Text from "../Text";
import { AddUser, FilterIcon } from "../../assets/svg";
import { useNavigate } from "react-router-dom";


const TableHeader = (props) => {
  const {
    title,
    search,
    onSearch,
    onFilter,
    filter,
    addUser
  } = props;

  const navigate = useNavigate();

  return (
    <Grid className="th-wrapper flexAlign" style={{ height: 70 }}>
      <h2 className="th-title">{title}</h2>
      <Grid className="flexAlign">
        <Text
          className="th-search"
          placeholder={"Search"}
          defaultValue={search}
          StartIcon={SearchIcon}
          onSearch={onSearch}
        />


        {/* **** Coming Soon ***** */}

        {filter &&
          <Grid style={{ marginLeft: "12px" }} className=" dflex aCenter cursorPointer" onClick={() => { onFilter() }}>
            <Button>
              <FilterIcon />
              <Typography style={{ color: "#9DA9BA" }} className="pl-1">Filter</Typography>
            </Button>
          </Grid>}

        {addUser &&

          <IconButton style={{
            height: '32px',
            width: '32px',
            borderRadius: '41px',
            background: 'linear-gradient(251deg, #8361FD 28.05%, #2F63ED 68.98%)',
            marginLeft: '12px',
            boxShadow: '0px 8px 24px 0px rgba(30, 64, 175, 0.15)'
          }} onClick={() => { navigate("profile") }}><AddUser /></IconButton>
        }

      </Grid>
    </Grid>
  );
};

export default TableHeader;
