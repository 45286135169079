import React from "react";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import SearchResults from "../containers/Search/searchList";

import Invitelist from "../containers/Invitation";





const searchRoutes = [
    {
        path: "/search",
        element: <ProtectedRoute />,
        children: [
            {
                path: "",
                element: <SearchResults></SearchResults>,
            },
            {
                path: "invitation",
                element: <Invitelist></Invitelist>
            },




        ],
    },
];

export default searchRoutes;
