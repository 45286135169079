import React from "react";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import ShowResults from "../components/AppHeader/showResults";

const showResultsRoutes = [
    {
        path: '/results',
        element: <ProtectedRoute />,
        children: [
            {
                path: '',
                element: <ShowResults />
            }
        ]
    }
]

export default showResultsRoutes
