import theme from "../theme";

const Style = {
  background: '#F4F6F9',
  height: '100%',
  alignContent: 'baseline',
  '.loginLeftContainer': {
    background: "#2563EB",
    height: "100%",
    padding: "30px",
    "& .slick-slider": {
      display: "flex",
      alignItems: "center",
      height: "calc(100% - 34px)",
    },
    "& .slick-slide img": {
      margin: "auto",
      paddingBottom: "24px",
    },
    "& .slick-list": {
      width: "100%",
    },
    "& .slick-dots li button:before": {
      fontSize: "10px",
      color: "#fff",
    },
    "& .slick-dots li": {
      margin: "0 2px",
    },
    "& .MuiTypography-root": {
      color: "#fff !important",
    },
    "& .description": {
      paddingTop: 10,
      color: "#F8FAFC !important",
    },
    "@media only screen and (max-width: 768px)": {
      "&.MuiGrid-root": {
        display: "none",
      },
    },
  },
  '.logo': {
    maxWidth: "100%",
    height: 34,
    width: 34,
    objectFit: "cover",
    cursor: "pointer",
  },
  '.mobileLogo': {
    display: "none",
    padding: "16px 0px",
    "@media only screen and (max-width: 768px)": {
      "&.MuiGrid-root": {
        display: "flex !important",
      },
    },
  },
  '.loginRightContainer': {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  '.loginContainer': {
    width: '100%',
    maxWidth: '480px',
    padding: '21px'
  },
  '.bodyWrapper': {
    height: 'calc(100% - 80px)'
  },
  '.bodyContainer': {
    flex: 1,
    height: '100%',
    overflowY: 'auto',
    background: theme.palette.containers.body,
    transition: 'all 0.5s'
  },
  '.sideBarRoot': {
    position: 'relative',
    // width: 280,
    height: "100%",
    background: theme.palette.containers.sidebar,
    padding: "16px 0px 24px 0px",
    transition: 'all 0.5s',
    visibility: 'visible',
    '& a': {
      textDecoration: 'none'
    },
    '.menuText': {
      whiteSpace: 'nowrap'
    },
    '&.closed': {
      '.menuText': {
        opacity: '0',
        visibility: 'hidden',
      },
      '.MuiListItem-root': {
        '.MuiListItemButton-root': {
          width: '70px'
        }
      },
      '.themeChange': {
        '.MuiTypography-root': {
          display: 'none'
        }
      },
      '.handleSidebarBtn': {
        right: '-12px',
        '& svg': {
          transform: 'rotate(180deg)'
        }
      }
    }
  },
  '.handleSidebarBtn': {
    position: 'absolute',
    right: '-20px',
    top: '20px',
    background: theme.palette.containers.greyBg6,
    height: '20px',
    width: '20px',
    borderRadius: '2px',
    padding: '0px'
  }
};

export default Style;
