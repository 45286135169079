import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Grid,

  Typography,
  styled,
} from "@mui/material";

// import style
import Style from "./style";
import Table from "../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../store/actions/agenciesAction";
import {
  setPageSize,
  setPage,
  setSearch,
  setOrdering,
} from "../../../store/reducers/agencyUserReducer";
import { useNavigate, useLocation } from "react-router-dom";
import moment from 'moment';

const userName = (props) => {
  const { rowData } = props;
  return (
    rowData?.name !== "" ?
      <Grid className="flexAlign">
        {rowData?.avatar_filename ?
          <img src={rowData?.avatar_filename} height="30px" width="30px" style={{ borderRadius: "100%" }} alt="" /> : <Avatar style={{ height: "30px", width: "30px" }} />
        }
        <Typography className="pl-1" style={{ color: "#0F172A" }}>{rowData?.name}</Typography>
      </Grid> : "-")

}

const Action = (props) => {
  const { rowData } = props;
  const navigate = useNavigate()
  return <Button onClick={() => { navigate(`/agencies/user/${rowData?.id}`, { replace: true }); }} className="moreOption">view</Button>
}
const CreatedOn = (props) => {
  const { rowData } = props;
  const val = moment(rowData.created_on).format('DD-MM-YYYY');
  return (rowData.created_on === "" || null) ? rowData.modified_on : val
}

const last_login = (props) => {
  const { rowData } = props;
  const val = moment(rowData.last_login).format('DD-MM-YYYY');
  return (rowData.last_login === "" || null) ? "-" : val
}

const cellMap = {
  name: userName,
  action: Action,
  created_on: CreatedOn,
  last_login: last_login
};

const AgecyUsers = (props) => {
  const { className } = props;
  const { list } = useSelector((state) => state.user);
  const { pagination, ordering, orderBy } = list;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const search = list.search || queryParams.get('searchText') || '';
  const dispatch = useDispatch();

  const handlePageSize = (e) => {
    dispatch(setPageSize(e.target.value));
  };

  const handlePagination = (e, pageValue) => {
    dispatch(setPage(pageValue));
  };

  const handleSearch = (searchVal) => {
    dispatch(setSearch(searchVal));
  };

  const onSort = (sortBy) => {
    dispatch(setOrdering(sortBy));
  };

  useEffect(() => {
    let keysearch = "";
    // let status = filter?.Status;
    // let price = filter?.Price;
    let filters = { ordering }
    if (search) {
      keysearch = `&search=${search}`;
    }
    const queryParams = `?page_size=${pagination?.page_size}&page=${pagination?.page_number}${keysearch}`

    dispatch(
      getUser({ queryParams, filters })
    );
  }, [
    dispatch,
    pagination?.page_size,
    pagination?.page_number,
    search,
    ordering,
  ]);

  return (
    <div className={`${className} h-100`}>
      <Table
        title={"Agency User"}
        headers={[...list?.headers || [], { field: 'action', label: 'action' }]}
        data={list?.results || []}
        cellMap={cellMap}
        search={search}
        pagination={pagination}
        handlePagination={handlePagination}
        handlePageSize={handlePageSize}
        handleSearch={handleSearch}
        ordering={ordering}
        orderBy={orderBy || []}
        onSort={onSort}
        sortField={ordering}
      />
    </div>
  );
};

AgecyUsers.defaultProps = {
  classes: {},
};

AgecyUsers.propTypes = {
  classes: PropTypes.object,
};

export default styled(AgecyUsers)(Style);
