import React, { useEffect, useState } from "react";
import { styled, Grid, Button, Typography, Tooltip, IconButton } from "@mui/material";
//import CopyIcon from "@mui/icons-material/ContentCopy";
import { CopyIcon } from "../../assets/svg";
import CheckIcon from "@mui/icons-material/Check";
import { useSelector, useDispatch } from "react-redux";
import { getInvitation } from "../../store/actions/searchAction";
import moment from 'moment';
import {
    setPageSize, setPage, setSearch, setOrdering, applyFilter,
    clearFilter,
} from "../../store/reducers/invitationreducer";
import Table from "../../components/Table";
import { useLocation } from "react-router-dom";

const styles = {
    ".avatar-cell": {
        display: "flex",
        alignItems: "center",
        ".avatar-title": {
            marginLeft: "8px",
        },
    },
};

const CreatedOnCell = (props) => {
    const { rowData } = props;
    return moment(rowData.created_on).format('DD-MM-YYYY');
}

const CopyIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
    },
}));

const InviteCodeCell = (props) => {
    const { rowData } = props;
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(rowData.invite_code);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <Tooltip title={copied ? "Copied!" : "Copy"} arrow>
            <CopyIconButton onClick={handleCopy}>
                {copied ? <CheckIcon /> : <CopyIcon />}
            </CopyIconButton>
        </Tooltip>
    );
}

const InviteEmailCell = (props) => {
    const { rowData } = props;
    return (
        <Typography style={{ color: "#0F172A" }}>{rowData?.invite_email}</Typography>
    );
}

const StatusCell = (props) => {
    const { rowData } = props;

    return (
        <div>
            {rowData.invite_status === "Draft" ? <StatusButton label={"Draft"} style={{ background: "#EDEDED", color: "#64748B" }} /> :

                (rowData.invite_status === "Sent") ? <StatusButton label={"Sent"} style={{ background: "#D4F6D2", color: "#0E8E2A" }} /> :

                    (rowData.invite_status === "Read") ? <StatusButton label={"Read"} style={{ background: "#D4F6D2", color: "#0E8E2A" }} /> :

                        (rowData.invite_status === "Accepted") ? <StatusButton label={"Accepted"} style={{ background: "#FFE1F0", color: "#FF5EB0" }} />

                            : (rowData.invite_status === "Rejected") ? <StatusButton label={"Rejected"} style={{ background: "#FFDFDD", color: "#FF3B3B" }} />

                                : <StatusButton label={"Unknown"} style={{ background: "#DEE4E7", color: "#37474F" }} />}
        </div>
    );
};

const StatusButton = (props) => {
    const { label, style } = props;
    return (
        <Grid>
            <Button style={{ ...style, minWidth: "86px" }}>
                {label}
            </Button>
        </Grid>
    )
}


const cellMap = {
    created_on: CreatedOnCell,
    invite_code: InviteCodeCell,
    invite_email: InviteEmailCell,
    invite_status: StatusCell
};

const Invitelist = (props) => {
    const { className } = props;
    const { list } = useSelector((state) => state.invite);
    const { pagination, ordering, orderBy, filter } = list;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const search = list.search || queryParams.get('searchText') || '';
    const dispatch = useDispatch();

    useEffect(() => {
        let keysearch = "";
        let status = filter?.Status;

        let filters = { status, ordering };

        if (search) {
            keysearch = `&search=${search}`;
        }
        const queryParams = `?page_size=${pagination?.page_size}&page=${pagination?.page_number}${keysearch}`;
        dispatch(getInvitation({ queryParams, filters }));
    }, [
        dispatch,
        pagination?.page_size,
        pagination?.page_number,
        search,
        ordering,
        filter,
    ]);

    const handlePageSize = (e) => {
        dispatch(setPageSize(e.target.value));
    };

    const handlePagination = (e, pageValue) => {
        dispatch(setPage(pageValue));
    };

    const handleSearch = (searchVal) => {
        dispatch(setSearch(searchVal));
    };

    const onSort = (sortBy) => {
        dispatch(setOrdering(sortBy));
    };



    const handleFilter = (data) => {
        dispatch(applyFilter(data));
    };

    const removeFilter = (data) => {
        dispatch(clearFilter());
    };

    const options = [
        {
            title: "Status",
            type: "checkbox",
            option: [
                { value: "Draft" },
                { value: "Sent" },
                { value: "Read" },
                { value: "Accepted" },
                { value: "Rejected" },
                { value: "Unknown" },
            ]
        },
    ];

    return (
        <div className={`${className} h-100`}>
            <Table
                title={"Invitation List"}
                headers={[...(list?.headers || [])]}
                data={list?.results || []}
                cellMap={cellMap}
                search={search}
                handleSearch={handleSearch}
                pagination={pagination}
                handlePagination={handlePagination}
                handlePageSize={handlePageSize}
                ordering={ordering}
                orderBy={orderBy || []}
                onSort={onSort}


                filter={true}
                handleFilter={handleFilter}
                removeFilter={removeFilter}
                appliedFilter={filter}
                options={options}
            />
        </div>
    );
};

export default styled(Invitelist)(styles);
