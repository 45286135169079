import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Avatar,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import Style from "./style";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceDetail } from "../../../store/actions/agenciesAction";

const InvoiceDetail = (props) => {
  const { className } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInvoiceDetail(id))
  }, [dispatch, id])

  const { invoiceDetail } = useSelector((state) => state.invoice);
  const data = invoiceDetail?.invoice_description ? JSON.parse(invoiceDetail?.invoice_description) : [];
  console.log(data, "222")

  return (
    <Grid
      container
      alignItems={"center"}
      className={`${className} p-3`}
    >
      <Grid container justifyContent={"space-between"} alignItems={"center"} className="">
        <Typography variant="h4" color="text.primaryText" className="pb15">Detail Page</Typography>
        <Grid className="dflex aCenter">
          {/* <TextField size="small"
          placeholder="Search here..."
          InputProps={{
            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
          }}
          />
          <Button variant="text" size="small" color="secondary" startIcon={<DownloadIcon />} className="ml-1">Download</Button> */}
        </Grid>
      </Grid>
      <Grid container className="invoiceContainer">
        <Grid item xs={12} align="center" sx={{ mb: 3 }} className="logoContainer">
          {invoiceDetail?.invoice_logo ? <img src={invoiceDetail?.invoice_logo} height="79px" width="79px" alt="Invoice Logo" /> : <Avatar />}
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={"space-between"} className="pb-1">
            <Grid>
              <Typography variant="body1" color={"text.greyText1"}>Date</Typography>
              <Typography variant="body1" color={"text.primaryText"} className="fw-600">{invoiceDetail?.invoice_date}</Typography>
            </Grid>
            <Grid>
              <Typography variant="body1" color={"text.greyText1"}>Invoice</Typography>
              <Typography variant="body1" color={"text.primaryText"} className="fw-600">#{invoiceDetail?.invoice_number}</Typography>
            </Grid>
          </Grid>
          <Grid container >
            <Grid item xs={9}>
              <Typography variant="body1" color={"text.greyText1"}>Invoice to</Typography>
              <Typography variant="h4" color={"text.primaryText"} className="fw-600">{invoiceDetail?.customer_name}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" color={"text.greyText1"} className="jEnd dflex" >Invoice from</Typography>
              <Typography variant="h4" color={"text.primaryText"} className="fw-600 jEnd dflex">{invoiceDetail?.invoice_title}</Typography>
              <div dangerouslySetInnerHTML={{ __html: invoiceDetail?.invoice_detail }} className="jEnd dflex" />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mt: 3 }}>
          <Grid container className="headerRow">
            <Grid item sx={{ flex: 2 }}><Typography variant="body1" color="text.greyText1">Description</Typography></Grid>
            <Grid item sx={{ flex: 1 }}><Typography variant="body1" color="text.greyText1">Qty</Typography></Grid>
            <Grid item sx={{ flex: 1 }}><Typography align="right" variant="body1" color="text.greyText1">Price({invoiceDetail?.invoice_currency})</Typography></Grid>
            <Grid item sx={{ flex: 1 }}><Typography align="right" variant="body1" color="text.greyText1">Total({invoiceDetail?.invoice_currency})</Typography></Grid>
          </Grid>
          {/* Loop */}
          {data?.map((data) => {
            return (<Grid container className="bodyRow">
              <Grid item sx={{ flex: 2 }}><Typography variant="body1" color="text.primaryText" className="fw-500">{data?.description}</Typography></Grid>
              <Grid item sx={{ flex: 1 }}><Typography variant="body1" color="text.primaryText" className="fw-500">{data?.price}</Typography></Grid>
              <Grid item sx={{ flex: 1 }}><Typography align="right" variant="body1" color="text.primaryText" className="fw-500">{data?.price}</Typography></Grid>
              <Grid item sx={{ flex: 1 }}><Typography align="right" variant="body1" color="text.primaryText" className="fw-500">{data?.total}</Typography></Grid>
            </Grid>)
          })}
          {/* Loop */}
        </Grid>

        <Grid container sx={{ mt: 3 }}>
          <Grid item sx={{ flex: 4 }}><Typography variant="body1" color="text.primaryText" className="fw-500" align="right">Total</Typography></Grid>
          <Grid item sx={{ flex: 1 }}><Typography variant="body1" color="text.primaryText" className="fw-500" align="right">{invoiceDetail?.invoice_currency_symbol}{invoiceDetail?.invoice_item_total}</Typography></Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item sx={{ flex: 4 }}><Typography variant="body1" color="text.primaryText" className="fw-500" align="right">Discount({invoiceDetail?.invoice_discount}%):</Typography></Grid>
          <Grid item sx={{ flex: 1 }}><Typography variant="body1" color="text.primaryText" className="fw-500" align="right">{invoiceDetail?.invoice_currency_symbol}{invoiceDetail?.invoice_discount_price}</Typography></Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item sx={{ flex: 4 }}><Typography variant="body1" color="text.primaryText" className="fw-500" align="right">{invoiceDetail?.invoice_tax_name}({invoiceDetail?.invoice_tax}%)</Typography></Grid>
          <Grid item sx={{ flex: 1 }}><Typography variant="body1" color="text.primaryText" className="fw-500" align="right">{invoiceDetail?.invoice_currency_symbol}{invoiceDetail?.invoice_tax_price}</Typography></Grid>
        </Grid>

        <Grid container className="footerRow" sx={{ mt: 2 }}>
          <Grid item sx={{ flex: 4 }} className="dflex jEnd aBaseline">
            <Typography variant="body1" color="text.primaryText" className="fw-700" align="right">Amount Due /</Typography>
            <Typography variant="body2" color="text.greyText" className="fw-500" align="right">{invoiceDetail?.invoice_currency}</Typography>
          </Grid>
          <Grid item sx={{ flex: 1 }}><Typography variant="body1" color="text.primaryText" className="fw-700" align="right">{invoiceDetail?.invoice_currency_symbol}{invoiceDetail?.invoice_total}</Typography></Grid>
        </Grid>

        <Grid container justifyContent="space-between" alignItems="end">
          {/* <Grid item>
            <Typography variant="body1" color="text.primaryText" className="fw-600 pb5">VAT182828282</Typography>
            <Grid item className="dflex aBaseline pb5">
              <Typography variant="body1" color="text.greyText1" className="fw-500">Sort-code:</Typography>
              <Typography variant="body1" color="text.primaryText" className="fw-600">84849499404</Typography>
            </Grid>
            <Grid item className="dflex aBaseline pb5">
              <Typography variant="body1" color="text.greyText1" className="fw-500">Account number:</Typography>
              <Typography variant="body1" color="text.primaryText" className="fw-600">44849</Typography>
            </Grid>
            <Typography variant="body1" color="text.primaryText" className="fw-600 pb5">IBAN 409843974</Typography>
            <Typography variant="body1" color="text.primaryText" className="fw-600">Thanks a lot</Typography>
          </Grid> */}
          {/* <Grid item>
            <Button variant="outlined" color="secondary" startIcon={<PDFDownloadIcon />}>Download PDF Invoice</Button>
          </Grid> */}
          <Grid container item xs={4} dangerouslySetInnerHTML={{ __html: invoiceDetail?.invoice_notes }} className="jEnd dflex" />

        </Grid>

      </Grid>
    </Grid>
  );
};

InvoiceDetail.defaultProps = {
  classes: {}
};

InvoiceDetail.propTypes = {
  classes: PropTypes.object
};

export default styled(InvoiceDetail)(Style);
