import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Grid,
  styled,
} from "@mui/material";
import Style from "./style";
import { getInvoice } from "../../store/actions/agenciesAction";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/Table";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setPageSize,
  setPage,
  setSearch,
  setOrdering,
  // addFilterRule,
  // updateFilterRule,
  // removeFilterRule,
  // applyFilter,
  // clearFilter,
} from "../../store/reducers/agencyInvoiceReducer";
import moment from 'moment';


const Invoices = (props) => {
  const { className } = props;

  const { loading, list } = useSelector((state) => state.invoice);
  const { pagination, ordering, orderBy, filter } = list;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const search = list.search || queryParams.get('searchText') || '';
  const dispatch = useDispatch();

  useEffect(() => {
    let keysearch = "";
    let filters = { ordering }
    if (search) {
      keysearch = `&search=${search}`;
    }
    const queryParams = `?page_size=${pagination?.page_size}&page=${pagination?.page_number}${keysearch}`;
    dispatch(
      getInvoice({ queryParams, filters })
    );
  }, [
    dispatch,
    pagination?.page_size,
    pagination?.page_number,
    search,
    ordering,
    filter.params,
  ]);

  const handlePageSize = (e) => {
    dispatch(setPageSize(e.target.value));
  };

  const handlePagination = (e, pageValue) => {
    dispatch(setPage(pageValue));
  };

  const handleSearch = (searchVal) => {
    dispatch(setSearch(searchVal));
  };

  const onSort = (sortBy) => {
    dispatch(setOrdering(sortBy));
  };

  const Action = (props) => {
    const { rowData } = props;
    const navigate = useNavigate()
    return <Button onClick={() => { navigate(`/invoices/detail/${rowData?.id}`, { replace: true }); }} className="moreOption">view</Button>
  }

  const invoice_logo = (props) => {
    const { rowData } = props;
    return (
      <Grid className="dflex aCenter">
        {rowData?.invoice_logo ?
          <img src={rowData?.invoice_logo} height="30px" width="30px" style={{ borderRadius: "100%" }} alt="" /> : <Avatar style={{ height: "30px", width: "30px" }} />
        }
      </Grid>
    )
  }

  const CreatedOn = (props) => {
    const { rowData } = props;
    const val = moment(rowData.created_on).format('DD-MM-YYYY');
    return val
  }

  const cellMap = {
    action: Action,
    invoice_logo: invoice_logo,
    created_on: CreatedOn
  };


  //topCard

  return (
    <div className={`${className} h-100`}>
      <Table
        title={"Agencies Invoice"}
        headers={[...(list?.headers || []), { field: 'action', label: 'action' }]}
        data={list?.results || []}
        cellMap={cellMap}
        search={search}
        pagination={pagination}
        handlePagination={handlePagination}
        handlePageSize={handlePageSize}
        handleSearch={handleSearch}
        ordering={ordering}
        orderBy={orderBy || []}
        onSort={onSort}
        sortField={ordering}
      // filter={filter}
      // onFilter={onFilter}
      />
      <div>{loading ? "Loading..." : ""}</div>
    </div>
  );
};

Invoices.defaultProps = {
  classes: {}
};

Invoices.propTypes = {
  classes: PropTypes.object
};

export default styled(Invoices)(Style);
