import { createSlice } from "@reduxjs/toolkit";
import { searchAgencies } from "../actions/searchAction";

const initialState = {
    agencies: {
        data: [],
        totalPages: 0,
    },
    users: {
        data: [],
        totalPages: 0,
    },
    invoices: {
        data: [],
        totalPages: 0,
    },
    invitations: {
        data: [],
        totalPages: 0,
    },
    loading: false,
    error: null,
};

const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        clearSearchResults(state) {
            state.agencies = {
                data: [],
                totalPages: 0,
            };
            state.users = {
                data: [],
                totalPages: 0,
            };
            state.invoices = {
                data: [],
                totalPages: 0,
            };
            state.invitations = {
                data: [],
                totalPages: 0,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchAgencies.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(searchAgencies.fulfilled, (state, action) => {
                state.agencies.data = action.payload?.data?.agencies;
                state.users.data = action.payload?.data?.users;
                state.invoices.data = action.payload?.data?.invoice;
                state.invitations.data = action.payload?.data?.invite;
                state.loading = false;
                state.error = null;
            })
            .addCase(searchAgencies.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "Failed to fetch data";
                state.agencies.data = [];
                state.users.data = [];
                state.invoices.data = [];
                state.invitations.data = [];
            })


    },
});

export const { clearSearchResults } = searchSlice.actions;
export default searchSlice.reducer;
