import { createSlice } from "@reduxjs/toolkit";
import { getAgencies, getAgenciesDetail } from "../actions/agenciesAction";

const initialState = {
  list: {
    results: [],
    headers: [],
    pagination: {
      count: 0,
      next: null,
      previous: null,
      page_size: 10,
      num_pages: 0,
      page_number: 1,
    },
    search: "",
    filter: {
      Status: [],
      name: {},
      Price: [0, 100],
      date: {},
    },
    ordering: { field: "created_on", sort: false },
  },
  agencydetail: {},
  loading: null,
};

export const agenciesSlice = createSlice({
  name: "agencies",
  initialState,
  reducers: {
    setPageSize(state, action) {
      state.list.pagination.page_size = action.payload;
    },
    setPage(state, action) {
      state.list.pagination.page_number = action.payload;
    },
    setSearch(state, action) {
      state.list.pagination.page_number = 1;
      state.list.search = action.payload;
    },
    setOrdering(state, action) {
      state.list.ordering = action.payload;
    },
    addFilterRule(state, action) {
      state.list.filter.rules.push({
        index: (Math.max(state.list.filter.rules.map((m) => m.index)) || 0) + 1,
        field: "",
        operator: "",
        value: "",
      });
    },
    removeFilterRule(state, action) {
      state.list.filter.rules = state.list.filter.rules.filter(
        (f) => f.index !== action.payload
      );
    },
    updateFilterRule(state, action) {
      state.list.filter.rules = state.list.filter.rules.map((m) => {
        if (m.index === action.payload.index) {
          return action.payload;
        }
        return m;
      });
    },
    applyFilter(state, action) {
      const title = action?.payload?.title
      state.list.pagination.page_number = 1;
      state.list.filter[title] = action?.payload?.data;
    },
    clearFilter(state) {
      state.list.pagination.page_number = 1;
      state.list.filter.Status = [];
      state.list.filter.Price = [0, 100];
    },
  },
  extraReducers: (builder) => {
    // add cases for getAgencies api calls
    builder
      .addCase(getAgencies.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAgencies.fulfilled, (state, { payload }) => {
        state.list = {
          ...state.list,
          ...payload,
        };
        state.loading = false;
      })
      .addCase(getAgencies.rejected, (state) => {
        state.loading = false;
      });
    // case to bring agencydetail
    builder
      .addCase(getAgenciesDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAgenciesDetail.fulfilled, (state, { payload }) => {
        state.agencydetail = {
          ...state.agencydetail,
          ...payload,
        };
        state.loading = false;
      })
      .addCase(getAgenciesDetail.rejected, (state) => {
        state.loading = false;
      });
    // add cases for other api calls
    // like builder.addCase
  },
});

export const {
  setPage,
  setPageSize,
  setSearch,
  setOrdering,
  addFilterRule,
  removeFilterRule,
  updateFilterRule,
  applyFilter,
  clearFilter,
} = agenciesSlice.actions;

export default agenciesSlice.reducer;
