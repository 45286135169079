import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import {
    Avatar,
    Button,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Select,
    TextField,
    Typography,
    styled,
} from "@mui/material";
import moment from 'moment';
import Style from "./style";
import { EditPen } from "../../../assets/svg";
import { useDispatch, useSelector } from "react-redux";
import { updateUser, deleteUser } from "../../../store/actions/userAction";
import { Form, useNavigate, useParams } from "react-router-dom";
import { getDetail } from "../../../store/actions/userAction";
import Delete from "../../../components/Dialogs";

const EditProfile = (props) => {
    const { className } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();


    //State
    const [, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const fileInputRef = useRef(null);
    const imagePreviewRef = useRef(null);
    const [edit, setEdit] = useState(false);

    const [open, setOpen] = useState(false)

    useEffect(() => {
        dispatch(getDetail(`?id=${id}`))
    }, [dispatch, id])

    const { list } = useSelector((state) => state.userManagement);
    const { userDetail } = list

    const [userData, setUserData] = useState({})

    useEffect(() => {
        if (userDetail) {
            setUserData({
                id: userDetail?.id,
                firstName: userDetail?.first_name,
                lastName: userDetail?.last_name,
                email: userDetail?.email,
                phone: userDetail?.contact_no,
                designation: userDetail?.job_title,
            })
        }
    }, [userDetail])

    const date = moment(userDetail?.date).format('YYYY-MM-DD HH:mm:ss');

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();

            reader.onload = () => {
                setPreviewUrl(reader.result);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleIconClick = () => {
        // Trigger the hidden file input element
        fileInputRef.current.click();
    };

    const handleSave = (e) => {
        e.preventDefault()
        dispatch(updateUser(`?${new URLSearchParams(userData).toString()}`))
        setEdit(!edit)
        navigate("/users")
    }

    const handleDelete = () => {
        dispatch(deleteUser(`?id=${id}`))
        navigate("/users")
    };

    return (
        <Grid
            container
            className={className}
        >
            <Grid container justifyContent={"space-between"} alignItems={"center"} className="mb-2">
                <Grid> <Typography variant="h4">User Profile</Typography></Grid>

                {!edit &&
                    <Grid className="dflex">
                        <Grid onClick={() => { setEdit(!edit) }}><Button variant="contained" color="primary" size="small" className="mr-1 btn" >Edit</Button></Grid>
                        <Grid onClick={() => { setOpen(!open) }}><Button variant="contained" color="secondary" className="mr-1 btn" size="small">Delete</Button></Grid>
                    </Grid>
                }
            </Grid>
            <Grid container className="profileContainer">
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} md={3}>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileInputChange}
                            />
                            <Grid className="profileImgContainer">
                                <IconButton className="uploadBtn" onClick={handleIconClick}><EditPen /></IconButton>
                                <Avatar ref={imagePreviewRef} src={previewUrl} alt="Uploaded" />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={9}>
                            <Form onSubmit={handleSave}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="body1" color={"text.primaryText"} className="pb-1">First Name</Typography>
                                        <TextField size="small" fullWidth value={userData?.firstName} required disabled={!edit}
                                            onChange={(e) => {
                                                setUserData((prev) => ({ ...prev, firstName: e.target.value }))
                                            }} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="body1" color={"text.primaryText"} className="pb-1">Last Name</Typography>
                                        <TextField size="small" fullWidth value={userData?.lastName} required disabled={!edit}
                                            onChange={(e) => {
                                                setUserData((prev) => ({ ...prev, lastName: e.target.value }))
                                            }} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="body1" color={"text.primaryText"} className="pb-1">Email Id</Typography>
                                        <TextField size="small" fullWidth value={userData?.email} required disabled={!edit} type="email"
                                            onChange={(e) => {
                                                setUserData((prev) => ({ ...prev, email: e.target.value }))
                                            }} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="body1" color={"text.primaryText"} className="pb-1">Phone</Typography>
                                        <TextField size="small" fullWidth value={userData?.phone} disabled={!edit}
                                            onChange={(e) => {
                                                setUserData((prev) => ({ ...prev, phone: e.target.value }))
                                            }} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="body1" color={"text.primaryText"} className="pb-1">Designation</Typography>
                                        <TextField size="small" fullWidth value={userData?.designation} disabled={!edit}
                                            onChange={(e) => {
                                                setUserData((prev) => ({ ...prev, designation: e.target.value }))
                                            }} />
                                    </Grid>

                                    {edit && <Grid className="w-100 dflex aEnd jEnd">
                                        <Button type="submit" variant="contained" color="primary" size="small" >update</Button>
                                        <Button variant="contained" color="secondary" className="ml-1 btn" size="small"
                                            onClick={() => setEdit(!edit)}>Cancel</Button>
                                    </Grid>}

                                </Grid>
                            </Form>
                        </Grid>

                        {!edit && <>
                            <Divider sx={{ mt: 5, mb: 5, width: '100%' }} />
                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" className="fw-600 pb-1">User Created by</Typography>
                                <Typography variant="body2" className="fw-500 pb-1" color={"text.greyText2"}>The above user was added by the following person into the sales dashboard.</Typography>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="body1" color={"text.primaryText"} className="pb-1">Added By</Typography>
                                        <TextField size="small"
                                            fullWidth
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><Avatar /></InputAdornment>,
                                                endAdornment: <InputAdornment position="end"><Typography variant="body2" color={"text.greyText4"} className="">Team Lead</Typography></InputAdornment>
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="body1" color={"text.primaryText"} className="pb-1">Date</Typography>
                                        <TextField size="small" value={date} disabled fullWidth

                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>}

                        <Divider sx={{ mt: 5, mb: 5, width: '100%' }} />
                        <Grid item xs={12} md={3}>
                            <Typography variant="body1" className="fw-600 pb-1">User Role</Typography>
                            <Typography variant="body2" className="fw-500 pb-1" color={"text.greyText2"}>Can edit and select the user role group for the above user, also custom the role access.</Typography>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body1" color={"text.primaryText"} className="pb-1">Added By</Typography>
                                    <Select size="small"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Delete
                open={open}
                handleClose={() => setOpen(false)}
                description="Are you sure you want to remove the user "
                handleDelete={handleDelete}
            />
        </Grid>
    );
};

// default props
EditProfile.defaultProps = {
    classes: {}
};

// prop types
EditProfile.propTypes = {
    classes: PropTypes.object
};


export default styled(EditProfile)(Style);
