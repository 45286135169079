import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import Style from "./style";
import { EditPen } from "../../../assets/svg";
import { useDispatch } from "react-redux";
import { saveUser } from "../../../store/actions/userAction";
import { Form, useNavigate } from "react-router-dom";
import moment from 'moment';

const Profile = (props) => {
  const { className } = props;

  const dispatch = useDispatch();
  //State
  const [, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const fileInputRef = useRef(null);
  const imagePreviewRef = useRef(null);

  const navigate = useNavigate()
  // const date = new Date().toLocaleString()
  const date = moment().format('YYYY-MM-DD HH:mm:ss');

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    designation: "",
  })
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();

      reader.onload = () => {
        setPreviewUrl(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleIconClick = () => {
    // Trigger the hidden file input element
    fileInputRef.current.click();
  };

  const handleSave = (e) => {
    e.preventDefault()
    dispatch(saveUser(`?${new URLSearchParams(userData).toString()}&date=${date}`))
    navigate("/users")
  }

  return (
    <Grid
      container
      className={className}
    >
      <Grid container justifyContent={"space-between"} alignItems={"center"} className="mb25">
        <Typography variant="h4">User Profile</Typography>
      </Grid>
      <Grid container className="profileContainer">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={3}>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileInputChange}
              />
              <Grid className="profileImgContainer">
                <IconButton className="uploadBtn" onClick={handleIconClick}><EditPen /></IconButton>
                <Avatar ref={imagePreviewRef} src={previewUrl} alt="Uploaded" />
              </Grid>
            </Grid>

            <Grid item xs={12} md={9}>
              <Form onSubmit={handleSave}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color={"text.primaryText"} className="pb-1">First Name</Typography>
                    <TextField size="small" fullWidth value={userData.firstName} required onChange={(e) => {
                      setUserData((prev) => ({ ...prev, firstName: e.target.value }))
                    }} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color={"text.primaryText"} className="pb-1">Last Name</Typography>
                    <TextField size="small" fullWidth required onChange={(e) => {
                      setUserData((prev) => ({ ...prev, lastName: e.target.value }))
                    }} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color={"text.primaryText"} className="pb-1">Email Id</Typography>
                    <TextField size="small" fullWidth required type="email" onChange={(e) => {
                      setUserData((prev) => ({ ...prev, email: e.target.value }))
                    }} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color={"text.primaryText"} className="pb-1">Phone</Typography>
                    <TextField size="small" fullWidth onChange={(e) => {
                      setUserData((prev) => ({ ...prev, phone: e.target.value }))
                    }} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" color={"text.primaryText"} className="pb-1">Designation</Typography>
                    <TextField size="small" fullWidth onChange={(e) => {
                      setUserData((prev) => ({ ...prev, designation: e.target.value }))
                    }} />
                  </Grid>

                  <Grid className="w-100 dflex aEnd jEnd">
                    <Button type="submit" variant="contained" color="primary" size="small" >Save</Button>
                  </Grid>

                </Grid>
              </Form>
            </Grid>
            <Divider sx={{ mt: 5, mb: 5, width: '100%' }} />

            {/* <Grid item xs={12} md={3}>
              <Typography variant="body1" className="fw-600 pb-1">User Created by</Typography>
              <Typography variant="body2" className="fw-500 pb-1" color={"text.greyText2"}>The above user was added by the following person into the sales dashboard.</Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" color={"text.primaryText"} className="pb-1">Added By</Typography>
                  <TextField size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><Avatar /></InputAdornment>,
                      endAdornment: <InputAdornment position="end"><Typography variant="body2" color={"text.greyText4"} className="">Team Lead</Typography></InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" color={"text.primaryText"} className="pb-1">Date</Typography>
                  <TextField size="small" value={date} disabled
                    fullWidth
                  />
                </Grid>
              </Grid> 
            </Grid>
            <Divider sx={{ mt: 5, mb: 5, width: '100%' }} />*/}
            <Grid item xs={12} md={3}>
              <Typography variant="body1" className="fw-600 pb-1">User Role</Typography>
              <Typography variant="body2" className="fw-500 pb-1" color={"text.greyText2"}>Can edit and select the user role group for the above user, also custom the role access.</Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" color={"text.primaryText"} className="pb-1">Added By</Typography>
                  <Select size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

// default props
Profile.defaultProps = {
  classes: {}
};

// prop types
Profile.propTypes = {
  classes: PropTypes.object
};


export default styled(Profile)(Style);
