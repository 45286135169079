import { createAsyncThunk } from "@reduxjs/toolkit";
import { userService } from "../services/userService";
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";

export const getUser = createAsyncThunk(
    "users/getUser",
    async (data, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setLoading("Logging Agencies..."));
            const response = await userService.getUser(data)
            dispatch(setLoading(null));
            return response?.data?.data;
        } catch (err) {
            dispatch(setLoading(null));
            parseErrorResponses(err?.response || err, dispatch);
            return rejectWithValue(err);
        }
    }
);

export const getDetail = createAsyncThunk(
    "users/getDetail",
    async (data, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setLoading("Logging Agencies..."));
            const response = await userService.getDetail(data)
            dispatch(setLoading(null));
            return response?.data?.data;
        } catch (err) {
            dispatch(setLoading(null));
            parseErrorResponses(err?.response || err, dispatch);
            return rejectWithValue(err);
        }
    }
);

export const saveUser = createAsyncThunk(
    "agencies/invoice",
    async (data, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setLoading("Logging Agencies..."));
            const response = await userService.saveUser(data);
            dispatch(setLoading(null));
            return response?.data?.data;
        } catch (err) {
            dispatch(setLoading(null));
            parseErrorResponses(err?.response || err, dispatch);
            return rejectWithValue(err);
        }
    }
);

export const updateUser = createAsyncThunk(
    "users/update",
    async (data, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setLoading("Logging Agencies..."));
            const response = await userService.updateUser(data);
            dispatch(setLoading(null));
            return response?.data?.data;
        } catch (err) {
            dispatch(setLoading(null));
            parseErrorResponses(err?.response || err, dispatch);
            return rejectWithValue(err);
        }
    }
);

export const deleteUser = createAsyncThunk(
    "users/delete",
    async (data, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setLoading("Logging Agencies..."));
            const response = await userService.deleteUser(data);
            dispatch(setLoading(null));
            return response?.data?.data;
        } catch (err) {
            dispatch(setLoading(null));
            parseErrorResponses(err?.response || err, dispatch);
            return rejectWithValue(err);
        }
    }
);